import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDevice } from "../../../contexts";
import { FancyNavMobile } from "./fancy-nav-mobile";
import {
	Wrapper,
	Basics,
	Stories,
	People,
	Literature,
} from "./fancy-nav.styled";

const FancyNav = () => {
	const history = useHistory();
	const go = useCallback(
		(url) => {
			history.push(url);
		},
		[history]
	);

	const { isMobile } = useDevice();

	if (isMobile) {
		return <FancyNavMobile />;
	}

	return (
		<Wrapper>
			<Basics onClick={() => go("/basics")}>
				<div className="title">First Steps</div>
			</Basics>
			<Literature onClick={() => go("/literature")}>
				<div className="title">Literature</div>
			</Literature>
			<People onClick={() => go("/people")}>
				<div className="title">People</div>
			</People>
			<Stories onClick={() => go("/stories")}>
				<div className="title">Stories</div>
			</Stories>
		</Wrapper>
	);
};

export default FancyNav;
