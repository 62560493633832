import { useApi } from "../../contexts";
import { Authorship, Navigation, StoryCard, Head, Tags } from "../../components";
import { Wrapper, Col } from "./stories-mobile.styled";
import { getVideos } from "../../utils";
import { useTags } from "../../hooks";

export const StoriesMobilePage = () => {
	const { stories } = useApi();
	const { tags, selectedTags, itemsVisible, handleTagClick } = useTags(
		stories,
		true
	);
	const videos = getVideos(stories);
	return (
		<Wrapper>
			<Head
				title="Into Russian - Stories"
				description="Animated fables, tales and stories for intermediate learners listen watch read Russian"
				canonical="stories"
			/>
			<Navigation page="stories" />
			<Col style={{ marginTop: "3.4vw", marginBottom: "3.4vw" }}>
				<div style={{ marginBottom: "2vw", fontFamily: "BitterBold" }}>Stories</div>
				<div>
					For Novice and Intermediate (A1/A2+). Animated fables, tales and short
					texts for intermediate learners
				</div>
			</Col>

			<Tags
				tags={[...tags].sort((a, b) => a.localeCompare(b))}
				page="stories"
				selected={selectedTags}
				handleTagClick={handleTagClick}
				style={{ marginTop: "3.4vw", marginBottom: "3.4vw" }}
			/>

			{itemsVisible.map((story) => (
				<StoryCard key={story.id} videos={videos} {...story} />
			))}
			<Authorship page="stories" />
			<Navigation page="stories" footer />
		</Wrapper>
	);
};
