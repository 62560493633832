import styled from "styled-components";
import { appear } from "../../constants";

export const Wrapper = styled.div`
	box-sizing: border-box;
	width: 33vw;
	height: 45vw;
	border: none;
	border-radius: 0.94vw;
	padding: 0.5vw;
	background-color: white;
	position: relative;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 0.5vw;
	overflow: hidden;
	cursor: pointer;

	@media (max-width: 1023px) {
		min-width: 87vw;
		min-height: unset;
		height: unset;
		padding: 1.7vw;
		border-radius: 4.3vw;
		margin: unset;
		padding-bottom: 4.7vw;
		margin-bottom: 4.7vw;
	}
`;

export const ImgContainer = styled.img(
	() => `
	height: auto;
  width: auto;
  max-height: 44%;
  max-width: 100%;
  margin-bottom: 2vw;
	border-radius: 0.94vw;
  position: relative;
  cursor: pointer;

	@media (max-width: 1023px) {
    border-radius: 3vw;
    margin-bottom: 7.5vw;

    ::before {
      opacity: 0.8;
    }
	}

  ::before {
    content: '';
    width: 5.9vw;
    height: 9.9vw;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
	  background: url(img/play2.svg);
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: contain;
    opacity: 0;
    transition: opacity 0.5s;
  }

  :hover::before {
    opacity: 1;
  }
`
);

export const Title = styled.div`
	font-size: 1.7vw;
	font-family: BitterBold;
	margin-left: 1vw;
	${appear}

	@media (max-width: 1023px) {
		font-size: 4vw;
		padding-left: 5vw;
		padding-right: 5vw;
	}
`;

export const Description = styled.div`
	font-size: 1.2vw;
	margin-top: 0.5vw;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 1vw;
	${appear}

	@media (max-width: 1023px) {
		font-size: 4vw;
		margin-top: 4vw;
		padding-left: 5vw;
		padding-right: 5vw;
		max-height: 45%;
		height: 45%;
	}
`;

export const Lang = styled.button`
	height: 3.5vw;
	width: 3.5vw;
	color: white;
	background: #94b0a6;
	border-top-left-radius: 100%;
	position: absolute;
	bottom: 0;
	right: 0;
	border: none;
	cursor: pointer;

	div {
		font-family: Bold;
		font-size: 1vw;
		transform: rotate(-45deg);
		padding-top: 0.5vw;
		@media (max-width: 1023px) {
			font-size: 3.5vw;
		}
	}

	@media (max-width: 1023px) {
		height: 11vw;
		width: 11vw;
	}
`;
