import {
	Authorship,
	Navigation,
	SoundButton,
	BasicCard,
	Tabs,
	Tab,
	Head,
	BasicGrammarCard,
} from "../../components";
import { Letter } from "./letter";
import { Wrapper, Col, Row, Delimiter, Hidden, Cards, Subtitle } from "./basics-mobile.styled";
import { LETTERS } from "./letters";
import { useBasics } from "./hooks";
import { getVideos } from "../../utils";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const Y_OFFSET = -200;

const to = (id: string) => {
	const element = document.getElementById(id);
	if (!element) return;

	const top =
		element.getBoundingClientRect().top + window.scrollY + Y_OFFSET;
	window.scrollTo({ top, behavior: "smooth" });
};

const toClass = (className: string) => {
	const element = document.getElementsByClassName(className)?.[0];

	if (!element) return;

	const y = element.getBoundingClientRect().top + window.scrollY + Y_OFFSET;
	window.scrollTo({ top: y, behavior: "smooth" });
};

export const BasicsMobilePage = () => {
	const {
		basics,
		basicsGrammar,
		basicsContacts,
		currentLetter,
		handleButtonClick,
		stopPlaying,
		onImageLoad,
		finishedLoading,
	} = useBasics();
	const videos = getVideos(basics);
	const videosGrammar = getVideos(basicsGrammar);
	const videosContacts = getVideos(basicsContacts);

	const [activeTab, setActiveTab] = useState<string>("alphabet");
  const alphabetRef = useRef<HTMLDivElement>(null);
  const grammarRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

	const location = useLocation();

	useEffect(() => {
    const navigateToSection = () => {
			if (!finishedLoading) return;
      const hash = location.hash;
      if (!hash) return;

      let element = null;
      switch (hash) {
        case '#alphabet':
          element = alphabetRef.current;
          break;
        case '#grammar':
          element = grammarRef.current;
          break;
        case '#first-contact':
          element = contactRef.current;
          break;
        default:
          return;
      }

      if (element) {
        const y = element.getBoundingClientRect().top + window.scrollY + Y_OFFSET;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    };

    navigateToSection();
  }, [location, finishedLoading]);

  useEffect(() => {
		const handleScroll = () => {
			const alphabet = alphabetRef.current!;
			const grammar = grammarRef.current!;
			const contact = contactRef.current!;

			if (!alphabet || !grammar || !contact) return;

			const alphabetTop = alphabet.getBoundingClientRect().top;
			const grammarTop = grammar.getBoundingClientRect().top - 1;
			const contactTop = contact.getBoundingClientRect().top - 1;

			const yOffset = -Y_OFFSET;

			if (alphabetTop < yOffset && grammarTop > yOffset) {
				setActiveTab("alphabet");
			} else if (grammarTop < yOffset && contactTop > yOffset) {
				setActiveTab("grammar");
			} else if (contactTop < yOffset) {
				setActiveTab("contact");
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};

  }, []);

	basics.forEach((el) => {
		onImageLoad(el.imgSrc);
	});

	basicsGrammar.forEach((el) => {
		onImageLoad(el.imgSrc);
	});

	basicsContacts.forEach((el) => {
		onImageLoad(el.imgSrc);
	});

	return (
		<Wrapper>
			<Head
				title="Into Russian - First Steps"
				description="An animated alphabet and basic grammar for beginners listen watch read learn Russian"
				canonical="basics"
			/>
			<Navigation className="basics" page="basics" />
			<Hidden id="alphabet" ref={alphabetRef} />
			<Col style={{
				marginLeft: "-6.8vw",
				marginRight: "-6.8vw",
				paddingBottom: "6.8vw",
				paddingTop: "3.4vw",
				paddingLeft: "6.8vw",
				paddingRight: "6.8vw",
				background: "linear-gradient(rgba(219, 225, 217, 0) -150%, rgba(219, 225, 217, 1) 140%)",
				backgroundColor: "#B9C5BF",
			}}>
				<div style={{ marginBottom: "1vw", fontFamily: "BitterBold" }}>First Steps</div>
				<div>
					For Beginners (A0+). An animated alphabet and basic grammar
				</div>
			</Col>
			<Tabs>
				<Tab
					onClick={() => {
						to("alphabet");
						window.location.hash = "#alphabet";
					}}
					className={activeTab === "alphabet" ? "selected" : ""}
				>
					Alphabet
				</Tab>
				<Tab
					onClick={() => {
						to("grammar");
						window.location.hash = "#grammar";
					}}
					className={activeTab === "grammar" ? "selected" : ""}
				>
					Grammar
				</Tab>
				<Tab
					onClick={() => {
						to("contact");
						window.location.hash = "#first-contact";
					}}
					className={activeTab === "contact" ? "selected" : ""}
				>
					First Contact
				</Tab>
			</Tabs>
			<Row style={{ marginTop: "12.2vw", marginBottom: "14.6vw" }}>
				<SoundButton isPlaying={!!currentLetter} onClick={handleButtonClick} />
				<Col>
					<div
						style={{
							display: "flex",
							flexFlow: "row wrap",
						}}
					>
						{LETTERS.map((letter) => (
							<Letter
								key={letter}
								letter={letter}
								isPlaying={letter === currentLetter}
								onClick={() => {
									!!currentLetter && stopPlaying();
									toClass(`letter-${letter}`);
								}}
							/>
						))}
					</div>
				</Col>
			</Row>

			{basics.map((basic) => (
				<BasicCard
					key={basic.id}
					videos={videos}
					stopAudio={stopPlaying}
					{...basic}
				/>
			))}

			<Delimiter />
			<Hidden id="grammar" ref={grammarRef}/>
			<Subtitle>Grammar</Subtitle>

			<Cards>
				{basicsGrammar.map((el) => {
					return (
						<BasicGrammarCard
							key={el.id}
							videos={videosGrammar}
							videoKey="grammarId"
							{...el}
						/>
					);
				})}
			</Cards>

			<Delimiter />
			<Hidden id="contact" ref={contactRef} />
			<Subtitle>First Contact</Subtitle>

			<Cards>
				{basicsContacts.map((el) => {
					return (
						<BasicGrammarCard
							key={el.id}
							videos={videosContacts}
							videoKey="contactId"
							{...el}
						/>
					);
				})}
			</Cards>

			<Authorship page="basics" />
			<Navigation page="basics" footer />
		</Wrapper>
	);
};
