import axios from "axios";
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { Howl } from "howler";
import { useParams } from "react-router-dom";
import { useApi } from "../../contexts";

interface Word {
	entries: number[];
	glueLeft: boolean;
	lead: string;
	lemma: string;
	lengthSec: number;
	startSec: number;
	text: string;
	trail: string;
}

interface Segment {
	isTitleLine: boolean;
	lengthSec: number;
	paraIx: number;
	startSec: number;
	words: Word[];
}

interface DictEntry {
	displayHead: string;
	head: string;
	senses: { srcDef: string; otherLangs: string }[];
}

const isSegment = (raw: unknown): raw is Segment => {
	return (
		(raw as Segment).lengthSec !== undefined &&
		(raw as Segment).paraIx !== undefined &&
		(raw as Segment).words !== undefined &&
		(raw as Segment).startSec !== undefined
	);
};

const isDictEntry = (raw: unknown): raw is DictEntry => {
	return (
		(raw as DictEntry).displayHead !== undefined &&
		(raw as DictEntry).head !== undefined
	);
};

export const useEpisode = () => {
	const { id } = useParams<{ id: string }>();
	const { episodes } = useApi();
	const [segments, setSegments] = useState<{ [paraIdx: string]: Segment[] }>(
		{}
	);
	const [dict, setDict] = useState<{ [lemma: string]: DictEntry }>({});
	const [trackPos, setTrackPos] = useState(0);
	const [isPlaying, setIsPlaying] = useState(false);
	const [lemma, setLemma] = useState<string>();
	const [title, setTitle] = useState<string>();
	const [duration, setDuration] = useState<number>();
	let player = useRef<Howl>();
	const episode = episodes.find((episode) => Number(id) === episode.id);

	const links = useMemo(() => {
		const episodesByBook = episodes
			.filter(({ bookId }) => bookId === episode?.bookId)
			.sort((a, b) => a.order - b.order);

		const i = episodesByBook.findIndex((el) => el.id === Number(id));
		return {
			prev: episodesByBook[i - 1]?.id,
			next: episodesByBook[i + 1]?.id,
		};
	}, [episode?.bookId, episodes, id]);

	useEffect(() => {
		return () => {
			if (!player.current) return;

			player.current.stop();
		};
	}, []);

	useEffect(() => {
		if (!episode || !episode.jsonSrc || !episode.m4aSrc || !episode.webmSrc) {
			return;
		}

		axios.get(episode.jsonSrc).then((res) => {
			if (res.data.segments) {
				const segmentsFiltered: Segment[] = res.data.segments.filter(isSegment);
				const segmentsNext = segmentsFiltered.reduce<{
					[paraIdx: string]: Segment[];
				}>((acc, el) => {
					const existing = acc[el.paraIx];
					if (existing) {
						return { ...acc, [el.paraIx]: [...existing, el] };
					} else {
						return { ...acc, [el.paraIx]: [el] };
					}
				}, {});

				setSegments(segmentsNext);
			}

			if (res.data.dictEntries) {
				const dictFiltered: DictEntry[] =
					res.data.dictEntries.filter(isDictEntry);
				const dictNext = dictFiltered.reduce<{ [lemma: string]: DictEntry }>(
					(acc, el) => {
						return { ...acc, [el.head]: el };
					},
					{}
				);
				setDict(dictNext);
			}

			if (res.data.title) {
				setTitle(res.data.title);
			}
		});
	}, [episode]);

	useEffect(() => {
		if (!episode || !episode.jsonSrc || !episode.m4aSrc || !episode.webmSrc) {
			return;
		}

		player.current = new Howl({
			src: [episode.webmSrc, episode.m4aSrc],
			volume: 1.0,
			html5: true,
			autoplay: false,
			onplay: () => {
				setIsPlaying(true);
			},
			onpause: () => {
				setIsPlaying(false);
			},
			onstop: () => {
				setIsPlaying(false);
			},
			onload: () => {
				setDuration(player.current?.duration());
			},
		});

		const playerInterval = setInterval(() => {
			const seek = player.current?.seek();
			if (!seek) return;

			setTrackPos(seek);
		}, 10);

		return () => {
			clearInterval(playerInterval);
		};
	}, [episode]);

	const play = useCallback(() => {
		if (isPlaying ?? !player.current) return;

		player.current.play();
	}, [isPlaying, player]);

	const pause = useCallback(() => {
		if (!isPlaying ?? !player.current) return;

		player.current.pause();
	}, [isPlaying, player]);

	const stop = useCallback(() => {
		if (!isPlaying ?? !player.current) return;

		setTrackPos(0);

		player.current.stop();
	}, [isPlaying, player]);

	const seek = useCallback(
		(pos: number) => {
			if (!player.current) return;

			player.current.seek(pos);
		},
		[player]
	);

	const prev = useCallback(() => {
		if (!player.current) return;

		const flat = Object.values(segments)
			.flat()
			.filter((el) => el.lengthSec > 0);

		const segIdx = flat.findIndex(
			(el) => el.startSec <= trackPos && el.startSec + el.lengthSec > trackPos
		);

		if (segIdx < 1) return;

		const seg = flat[segIdx - 1];

		player.current.seek(seg.startSec);
	}, [player, trackPos, segments]);

	const next = useCallback(() => {
		if (!player.current) return;

		const flat = Object.values(segments).flat();

		const seg = flat.find((el) => el.startSec > trackPos);

		if (!seg) return;

		player.current.seek(seg.startSec);
	}, [player, trackPos, segments]);

	return {
		segments,
		trackPos,
		isPlaying,
		title,
		dict,
		lemma,
		duration,
		links,
		play,
		pause,
		prev,
		next,
		seek,
		stop,
		setLemma,
	};
};
