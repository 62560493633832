import styled from "styled-components";
import { Tag } from "./tag";

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
`;

interface Color {
	textColor: string;
	selectedTextColor: string;
	hoverTextColor: string;
	clickTextColor: string;
	selectedBackgroundColor: string;
	hoverBackgroundColor: string;
	clickBackgroundColor?: string;
}

const COLORS: { [page: string]: Color } = {
	stories: {
		textColor: "black",
		selectedTextColor: "white",
		hoverTextColor: "black",
		clickTextColor: "white",
		selectedBackgroundColor: "#5CAA83",
		hoverBackgroundColor: "#5CAA83",
		clickBackgroundColor: "#1FD57A",
	},
	literature: {
		textColor: "black",
		selectedTextColor: "white",
		hoverTextColor: "black",
		clickTextColor: "white",
		selectedBackgroundColor: "#25A7B9",
		hoverBackgroundColor: "#0DD2EC",
	},
	persons: {
		textColor: "black",
		selectedTextColor: "white",
		hoverTextColor: "black",
		clickTextColor: "white",
		selectedBackgroundColor: "#5CAA83",
		hoverBackgroundColor: "#5CAA83",
		clickBackgroundColor: "#1FD57A",
	},
};

export const Tags = ({
	tags,
	page,
	selected,
	handleTagClick,
	style,
}: {
	tags: string[];
	page: string;
	selected: string[];
	handleTagClick: (tag: string) => void;
	style?: React.CSSProperties;
}) => {
	const { textColor, selectedTextColor, selectedBackgroundColor, hoverTextColor, clickTextColor, hoverBackgroundColor, clickBackgroundColor } =
		COLORS[page];
	
	return (
		<Wrapper style={style}>
			{tags.map((tag) => {
				return (
					<Tag
						key={tag}
						tag={tag}
						onClick={() => handleTagClick(tag)}
						selected={selected.includes(tag)}
						textColor={textColor}
						selectedTextColor={selectedTextColor}
						hoverTextColor={hoverTextColor}
						clickTextColor={clickTextColor}
						selectedBackgroundColor={selectedBackgroundColor}
						hoverBackgroundColor={hoverBackgroundColor}
						clickBackgroundColor={clickBackgroundColor || hoverBackgroundColor}
					/>
				);
			})}
		</Wrapper>
	);
};
