import styled from "styled-components";
import { appear } from "../../constants";

export const Wrapper = styled.div`
	min-height: 100vh;
	min-width: 100vw;
	padding: 6.8vw 16vw;
	background: #b9c5bf;
	${appear}
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	font-family: Bitter;
	font-size: 2.4vw;
`;

export const Grid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-row-gap: 1.9vw;
	grid-column-gap: 1.7vw;
	margin-bottom: 3.4vw;
`;
