import { useApi } from "../../contexts";
import { Navigation, Head, AuthorCard, Bookshelf } from "../../components";
import { Wrapper, Col, SearchInput, SearchModal, SearchResults } from "./literature-mobile.styled";
import { isAuthorResult, isBookResult, useLiteratureSearch } from "./hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { SearchAuthorCard, SearchBookCard, SearchNotFountCard } from "../../components/search-card/search-card";
import SearchDropdown from "../../components/search-dropdown/search-dropdown-mobile";

export const LiteratureMobilePage = () => {
	const { authors } = useApi();
	const { search, setSearch, searchResults, searchResultsVisible, setSearchResultsVisible, lastSearch, onSearch } = useLiteratureSearch();
	const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
	const modalSearchRef = useRef<HTMLInputElement>(null);
	const [scrollTarget, setScrollTarget] = useState<HTMLElement | null>(null);
	const [showSearchDropdown, setShowSearchDropdown] = useState(false);
	const term = useMemo(() => search.toLocaleLowerCase().trim(), [search]);

	useEffect(() => {
		if (isSearchModalVisible) {
			modalSearchRef.current?.focus();
			setSearchResultsVisible(false);
		}
	}, [isSearchModalVisible, modalSearchRef, setSearchResultsVisible]);

	useEffect(() => {
		if (searchResultsVisible) {
			setIsSearchModalVisible(false);
		}
		if (!searchResultsVisible) {
			setSearch("");
		}
	}, [searchResultsVisible, setIsSearchModalVisible, setSearch]);

	useEffect(() => {
		if (scrollTarget && !searchResultsVisible) {
			scrollTarget.scrollIntoView({ behavior: "smooth", block: "center" });
		}
		if (scrollTarget && searchResultsVisible) {
			setScrollTarget(null);
		}
		if (!searchResultsVisible) {
			setSearch("");
		}
	}, [searchResultsVisible, scrollTarget, setSearch]);

	useEffect(() => {
		if (searchResultsVisible) return;
		if (searchResults && searchResults.length > 0) {
			setShowSearchDropdown(true);
		}
	}, [searchResults, searchResultsVisible]);

	return (
		<Wrapper>
			<Head
				title="Into Russian - Literature"
				description="Russian literature to listen and read with vocabulary. Thematic guide of Russian literature: listen, watch, read"
				canonical="literature"
			/>
			<Navigation page="literature" />
			<Col style={{ marginTop: "3.4vw", marginBottom: "3.4vw" }}>
				<div style={{ marginBottom: "1vw", fontFamily: "BitterBold" }}>Literature</div>
				<div>
					For All Levels. Who’s afraid of Dostoevsky? A listening and reading
					library
				</div>
				<div
					style={{
						fontFamily: "Bold",
						marginBottom: "1vw",
						cursor: "pointer",
						display: "flex",
						alignItems: "center",
						textDecoration: "underline",
						fontSize: "14px",
						marginTop: "20px",
					}}
					onClick={() => {
						setSearchResultsVisible(false);
						setScrollTarget(document.getElementById("book-cards"));
						if (scrollTarget && !searchResultsVisible) {
							scrollTarget.scrollIntoView({ behavior: "smooth", block: "center" });
						}
					}}
				>
					<img
						src="/img/ico_slider_right.png"
						alt=""
						style={{ marginRight: "12px", maxHeight: "22px", maxWidth: "22px" }}
					/>
					See the thematic guide{" "}
				</div>
			</Col>

			<SearchInput>
				<input
					type="text" 
					placeholder="Search by author or title"
					maxLength={100}
					value={search || ""}
					onChange={(e) => setSearch(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							onSearch();
						}
					}}
					onFocus={() => {
						setIsSearchModalVisible(true);
					}}
				/>
				<img
					src="/img/search-icon.svg"
					alt=""
					onClick={onSearch}
				/>
			</SearchInput>
			
			<SearchModal 
				style={{display: isSearchModalVisible ? "flex" : "none"}}
				onClick={() => {
					setIsSearchModalVisible(false);
				}}
			>
				<div className="modal-top"
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<img
							src="/img/ico_slider_right.png"
							alt=""
							style={{ transform: "rotate(180deg)" }}
							onClick={() => {
								setIsSearchModalVisible(false);
							}}
						/>
						<input
							ref={modalSearchRef}
							type="text"
							placeholder="Search"
							maxLength={100}
							value={search || ""}
							onChange={(e) => setSearch(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									onSearch();
								}
							}}
						/>
						<img
							src="/img/search-icon.svg"
							alt=""
							onClick={onSearch}
						/>
				</div>
				{showSearchDropdown && 
					<SearchDropdown
						searchResults={searchResults.sort((a, b) => {
							const termA = isAuthorResult(a) ? a.data.nameRu.toLocaleLowerCase() : isBookResult(a) ? a.data.title.toLocaleLowerCase() : "";
							const termB = isAuthorResult(b) ? b.data.nameRu.toLocaleLowerCase() : isBookResult(b) ? b.data.title.toLocaleLowerCase() : "";
							// first return if starts with term
							if (termA.startsWith(term) && !termB.startsWith(term)) return -1;
							if (!termA.startsWith(term) && termB.startsWith(term)) return 1;
							// then return in alphabetical order
							return termA.localeCompare(termB);
						})}
					/>
				}
			</SearchModal>

			{!searchResultsVisible && authors.map((author) => (
				<AuthorCard key={author.id} {...author} />
			))}

			{searchResultsVisible && searchResults &&
				<SearchResults>
					<h2>Search Results: {lastSearch}</h2>
					{searchResults.map((item) => {
						let card;
						if (isAuthorResult(item)) {
							card = <SearchAuthorCard key={item.data.id} 
												{...item.data}
										 />;
						}
						if (isBookResult(item)) {
							card = <SearchBookCard key={item.data.id} {...item.data} />;
						}
						return card || null;
					})}
					{searchResults.length === 0 &&
						<SearchNotFountCard />
					}
				</SearchResults>
			}

			<div id="book-cards" />
			{!searchResultsVisible &&
				<Bookshelf />
			}
			<Navigation page="literature" footer />
		</Wrapper>
	);
};
