import styled from "styled-components";
import { appear } from "../../constants";

export const Wrapper = styled.div`
	position: relative;
	min-height: 100vh;
	min-width: 100vw;
	padding: 6.8vw 16vw;
	background: #b9c5bf;
	${appear}

	@media (max-width: 1023px) {
		padding: 6.8vw;
	}
`;

export const Header = styled.div`
	font-family: Bitter;
	font-size: 3.35vw;
	margin-top: 5.35vw;
	margin-bottom: 1.7vw;
	min-height: 7vw;

	@media (max-width: 1023px) {
		font-size: 4.5vw;
		margin-bottom: 5vw;
		margin-left: 5vw;
	}
`;

export const Card = styled.div`
	position: relative;
	background: white;
	width: 100%;
	border-radius: 1vw;
	padding: 2.7vw 2.3vw;
	margin-top: 1.7vw;

	.head {
		max-width: 65%;
		margin-bottom: 4vw;
		min-height: 7vw;

		@media (max-width: 1023px) {
			max-width: 100%;
			margin-bottom: 8vw;
		}
	}

	@media (max-width: 1023px) {
		border-radius: 5vw;
		padding: 5vw;
	}
`;

export const Title = styled.div`
	font-size: 1.2vw;
	font-family: Bold;
	margin-bottom: 0.5vw;

	@media (max-width: 1023px) {
		font-size: 4.5vw;
	}
`;

export const Description = styled.div`
	font-size: 1vw;

	@media (max-width: 1023px) {
		font-size: 2.5vw;
	}
`;

export const Row = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	margin-bottom: 1.7vw;
	@media (max-width: 1023px) {
		flex-direction: column;
		margin-bottom: 10vw;
		:last-of-type {
			margin-bottom: 0;
		}
	}
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Img = styled.div(
	({ src }: { src: string }) => `
  background: url(${src});
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 1vw;
	border-radius: 0.94vw;
  content: '';
  min-width: 21.4vw;
  height: 12.1vw;
  margin-right: 1.7vw;
	@media (max-width: 1023px) {
		min-width: unset;
    padding-top: 50%;
    width: 100%;
    border-radius: 5vw;
	}
`
);

export const Lang = styled.button`
	height: 3.5vw;
	width: 3.5vw;
	color: white;
	background: #94b0a6;
	border-top-left-radius: 100%;
	border-bottom-right-radius: 0.94vw;
	position: absolute;
	bottom: 0;
	right: 0;
	border: none;
	cursor: pointer;
	z-index: 100;

	div {
		font-family: Bold;
		font-size: 1vw;
		transform: rotate(-45deg);
		padding-top: 0.5vw;
		@media (max-width: 1023px) {
			font-size: 3.5vw;
		}
	}

	@media (max-width: 1023px) {
		height: 11vw;
		width: 11vw;
		border-bottom-right-radius: 5vw;
	}
`;

export const Grid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-row-gap: 2.4vw;
	grid-column-gap: 1.7vw;
	margin-bottom: 1.7vw;

	@media (max-width: 1023px) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`;

export const GridCreators = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-row-gap: 2.4vw;
	grid-column-gap: 1.7vw;
	margin-bottom: 3.4vw;

	@media (max-width: 1023px) {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const TeamTitle = styled.div`
	margin-bottom: 1.7vw;
	font-size: 1.7vw;
	font-family: BitterBold;
	@media (max-width: 1023px) {
		font-size: 4vw;
		margin-bottom: 4vw;
		margin-top: 5vw;

		&:last-of-type {
			margin-top: 10vw;
		}
	}
`;

export const TeamSubtitle = styled.div`
	margin-bottom: 1.7vw;
	font-size: 1vw;
	@media (max-width: 1023px) {
		font-size: 3.8vw;
	}
`;

export const PageTitle = styled.div`
	display: flex;
	flex-direction: column;
	font-family: Bitter;
	font-size: 2.4vw;
	margin-top: 3.4vw;
	margin-bottom: 3.4vw;

	@media (max-width: 1023px) {
		font-size: 4.5vw;
		margin-bottom: 10vw;
		margin-top: 8vw;

		br {
			display: none;
		}
	}
`;
