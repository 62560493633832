import { useState } from "react";
import { useApi } from "../../contexts";
import styled, { keyframes } from "styled-components";
import { Book as BookType } from "../../types";

const ANIMATION_DURATION = 3000;
const CB_DELAY = ANIMATION_DURATION - 300;

const mobileAnimation = keyframes`
   0% {
	  height: 24px;
  }

  100% {
	  height: 24px;
		transform: rotate(90deg);
    margin-bottom: 90vw;
  }
`;

const Container = styled.div`
	background: white;
	border-radius: 12px;
	cursor: pointer;
	border-top: 0px solid red;

	&:nth-of-type(2n) {
		background-color: #dce8dd;
	}
	&:nth-of-type(3n) {
		background-color: #cbe3dd;
	}
	&:nth-of-type(4n) {
		background-color: #dce8dd;
	}

	height: 24px;
	border-radius: 4px;
	padding: 4px 6px;
	margin-bottom: 6px;
	transform-origin: left bottom;

	&.clicked {
		animation-name: ${mobileAnimation};
		animation-duration: ${ANIMATION_DURATION}ms;
	}
`;

const Inner = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	border-left: 2px solid #c4c4c4;
	border-right: 2px solid #c4c4c4;

	font-size: 2vw;
	padding: 0px 1vw;

	&.clicked {
		border-left: none;
		border-right: none;
	}
`;

const Author = styled.div`
	font-family: Bitter;
	color: #807a7a;
`;
const Title = styled.div`
	&.smallerText {
		font-size: 1.5vw;

		@media (max-width: 1023px) {
			font-size: 10px;
		}
	}
`;

export const BookClosedMobile = ({
	title,
	authorId,
	onClick,
}: Pick<BookType, "title" | "authorId"> & { onClick: () => void }) => {
	const { authors } = useApi();
	const [clicked, setClicked] = useState("");

	const author = authors.find((el) => el.id === authorId);

	return (
		<Container
			onClick={() => {
				setClicked("clicked");
				setTimeout(() => {
					setClicked("");
					onClick();
				}, CB_DELAY);
			}}
			className={clicked}
		>
			<Inner className={clicked}>
				{author && (
					<Author className={`author ${clicked}`}>
						{author.shortNameRu ?? author.nameRu}
					</Author>
				)}
				<Title
					className={`title ${clicked} ${
						title.length > 40 ? "smallerText" : ""
					}`}
				>
					{title.substring(0, 50)}
					{title.length > 50 ? "..." : ""}
				</Title>
			</Inner>
		</Container>
	);
};
