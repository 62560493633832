import React from "react";

import { AboutPageCreator } from "../../types";
import { Wrapper, Image, Desc } from "./creator-card.styled";

export const CreatorCard = ({
	nameRu,
	nameEn,
	roleRu,
	roleEn,
	imgSrc,
	isEnShown,
}: Omit<AboutPageCreator, "id" | "order"> & { isEnShown: boolean }) => {
	return (
		<Wrapper>
			<Image imgSrc={imgSrc} />
			{!isEnShown && (
				<Desc>
					&nbsp;{roleRu}
					{roleRu.length < 25 && <br />}&nbsp;
					{nameRu}
				</Desc>
			)}
			{isEnShown && (
				<Desc>
					{roleEn}
					{roleEn.length < 25 && <br />}&nbsp;
					{nameEn}
				</Desc>
			)}
		</Wrapper>
	);
};
