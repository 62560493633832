import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 14vw;

	@media (max-width: 1023px) {
		flex-direction: row;
		max-width: unset;
		justify-content: flex-start;
	}
`;

export const Image = styled.div<{ imgSrc: string }>(
	({ imgSrc }) => `
  border-radius: 50%;
  background: url(${imgSrc});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 7.5vw;
  width: 7.5vw;

	@media (max-width: 1023px) {
    height: 18.7vw;
    width: 18.7vw;
    margin-right: 5.3vw;
  }
`
);

export const Desc = styled.div`
	font-family: Bold;
	text-align: center;
	font-size: 1vw;
	@media (max-width: 1023px) {
		font-family: Regular;
		font-size: 3.2vw;
		max-width: 44vw;
		text-align: left;
	}
`;
