import { useState } from "react";
import { Person, VideoItem } from "../../types";
import { useVideoModal } from "../../contexts";

import {
	Wrapper,
	ImgContainer,
	Title,
	Description,
	Lang,
} from "./person-card.styled";

export const PersonCard = ({
	titleRu,
	titleEn,
	descriptionRu,
	descriptionEn,
	imgSrc,
	videoSrc,
	transcript,
	videos,
	isHidden,
}: Person & { videos: VideoItem[], isHidden?: boolean }) => {
	const { showVideo } = useVideoModal();
	const [isEnShown, setIsEnShown] = useState(true);
	const videoId = videos.findIndex((el) => el.videoId === videoSrc);

	return (
		<Wrapper
			className="person-card-wrapper"
			onClick={() => {
				showVideo(videos, videoId);
				const url = new URL(window.location.toString());
				url.searchParams.set("person", videoId.toString());
				window.history.pushState(null, "", url.toString());
			}}
			style={{ display: isHidden ? "none" : "flex" }}
		>
			<ImgContainer src={imgSrc} alt={titleEn} />
			{isEnShown && <Title>{titleEn}</Title>}
			{!isEnShown && <Title>{titleRu}</Title>}
			{isEnShown && <Description>{descriptionEn}</Description>}
			{!isEnShown && <Description>{descriptionRu}</Description>}
			<Lang
				onClick={(e) => {
					e.stopPropagation();
					setIsEnShown(!isEnShown);
				}}
			>
				<div>{isEnShown ? "RUS" : "EN"}</div>
			</Lang>
		</Wrapper>
	);
};
