import { Book, Episode, VideoItem } from "./types";

const DEFAULT_DATE = new Date("2021-05-08 20:30:34");

export const toTags = (str: string | undefined): string[] => {
	if (!str) return [];
	if (str.length === 0) return [];

	return str.split(",").map((el) => el.trim());
};

export const isEpisodeClickable = (episode: Episode): boolean => {
	return (
		!!episode && !!episode.jsonSrc && !!episode.m4aSrc && !!episode.webmSrc
	);
};

export const toDate = (s: string | undefined) => {
	try {
		if (s) {
			const res = new Date(s);
			return res;
		}
		return DEFAULT_DATE;
	} catch (e) {
		return DEFAULT_DATE;
	}
};

export const getBooksWithLength = (episodes: Episode[], books: Book[]) => {
	const booksLength = episodes.reduce<{ [bookId: string]: number }>(
		(acc, el) => {
			const bookId = el.bookId;
			const length = el.content.length;

			if (acc[bookId]) {
				return { ...acc, [bookId]: acc[bookId] + length };
			}
			return { ...acc, [bookId]: length };
		},
		{}
	);

	return books
		.map((book) => {
			const length = booksLength[book.id.toString()] ?? book.length;
			return { ...book, length };
		})
		.sort((a, b) => a.length - b.length);
};

export const isOdd = (_: unknown, i: number) => i & 1;
export const isEven = (_: unknown, i: number) => !isOdd(_, i);

export const playAudio = (url: string) => {
	return new Promise((resolve, reject) => {
		try {
			const audio = new Audio(url);
			audio.addEventListener(
				"ended",
				() => {
					resolve("played");
				},
				false
			);

			audio.play().catch((e) => reject(e));
		} catch (e) {
			reject("failed");
		}
	});
};

export const getVideos: (
	items: { videoSrc?: string; transcript?: string }[]
) => VideoItem[] = (items) => {
	return items
		.filter(({ videoSrc }) => !!videoSrc)
		.map(({ videoSrc, transcript }) => {
			return {
				videoId: videoSrc!,
				transcript,
			};
		});
};

export const sortByLastWord = (a: string, b: string) => {
	const aLast = a.trim().split(" ").pop() ?? "";
	const bLast = b.trim().split(" ").pop() ?? "";
	return aLast.localeCompare(bLast);
};

export const getFirstEpisode = (episodes: Episode[], bookId: number) => {
	return episodes
		.filter((el) => el.bookId === bookId)
		.sort((a, b) => a.order - b.order)[0];
}

export const damerauLevenshtein = (s1: string, s2: string): number => {
	s1 = s1.toLowerCase();
	s2 = s2.toLowerCase();
  const s1len = s1.length;
  const s2len = s2.length;
  const matrix: number[][] = Array.from({ length: s1len + 1 }, () => new Array(s2len + 1).fill(0));

  for (let i: number = 1; i <= s1len; i++) {
    matrix[i][0] = i;
  }

  for (let j: number = 1; j <= s2len; j++) {
    matrix[0][j] = j;
  }

  for (let i: number = 1; i <= s1len; i++) {
    for (let j: number = 1; j <= s2len; j++) {
      if (s1[i - 1] === s2[j - 1]) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = 1 + Math.min(matrix[i - 1][j], matrix[i][j - 1], matrix[i - 1][j - 1]);
        if (i > 1 && j > 1 && s1[i - 1] === s2[j - 2] && s1[i - 2] === s2[j - 1]) {
          matrix[i][j] = Math.min(matrix[i][j], matrix[i - 2][j - 2] + 1);
        }
      }
    }
  }
  return matrix[s1len][s2len];
}

export const fuzzyDistance = (term: string, s: string): [number, string] => {
	if (term.length < 4) return [Infinity, ''];
	term = term.toLowerCase();
	s = s.toLowerCase();
	let minDistance = Infinity;
	let minDistanceToken = '';
	for (const token of s.split(' ')) {
		const oldMinDistance = minDistance;
		const distance = damerauLevenshtein(term, token);
		if (distance / token.length >= 0.5) continue;
		minDistance = Math.min(minDistance, distance);
		if (minDistance < oldMinDistance) {
			minDistanceToken = token;
		}
	}
	return [minDistance, minDistanceToken];
}