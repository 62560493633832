import styled from "styled-components";

const Wrapper = styled.div`
	font-size: 1.2vw;
	text-align: left;
	margin-top: 2.6vw;
	color: white;

	a {
		color: white;
	}

	@media (max-width: 1023px) {
		font-size: 2.9vw;
		text-align: unset;
		margin-top: 10vw;
	}
`;

export const Rights = () => {
	return (
		<Wrapper>
			&copy; All rights reserved 2021. Website made by:&nbsp;&nbsp;
			<a
				href="https://parsuna.ru/eng/index.html"
				target="_blank"
				rel="noreferrer noopener"
			>
				PARSUNA
			</a>
		</Wrapper>
	);
};
