import styled from "styled-components";

const Button = styled.button<{ isPlaying: boolean }>(
	({ isPlaying }) => `
	width: 4.63vw;
	height: 3.54vw;
	background: ${isPlaying ? "#FF6A29" : "white"};
	border: none;
	border-radius: 2vw;
	background-image: url(/img/${isPlaying ? "pause" : "broadcast"}.png);
	background-position: center;
	background-size: ${isPlaying ? "1vw" : "1.8vw"};
	background-repeat: no-repeat;
	cursor: pointer;
	margin-left: 2.5vw;

	@media (max-width: 1023px) {
		min-width: 9vw;
		height: 9vw;
		border-radius: 50%;
	  background-size: ${isPlaying ? "2.4vw" : "4vw"};
		margin-left: 0;
		margin-right: 4.8vw;
	}
`
);

export const SoundButton = ({
	isPlaying,
	onClick,
}: {
	isPlaying: boolean;
	onClick: () => void;
}) => {
	return <Button isPlaying={isPlaying} onClick={onClick} />;
};
