import styled from "styled-components";
import { appear } from "../../constants";

export const Wrapper = styled.div`
	box-sizing: border-box;
	width: 31.7vw;
	height: 35.5vw;
	border: none;
	border-radius: 0.94vw;
	padding: 0.5vw;
	background-color: white;
	position: relative;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 1.2vw;
	overflow: hidden;
	cursor: pointer;

	@media (max-width: 1023px) {
		min-width: 70.1vw;
		min-height: 141vw;
		padding: 5.9vw;
		border-radius: 4.3vw;
	}
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	${appear}

	.author-name {
		margin-left: 2vw;
	}

	@media (max-width: 1023px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const ImgContainer = styled.div`
	background-color: #afd0d7;
	height: 12.4vw;
	width: 12.4vw;
	min-height: 12.4vw;
	min-width: 12.4vw;
	border-radius: 50%;

	@media (max-width: 1023px) {
		height: 25vw;
		width: 25vw;
		margin-bottom: 5.3vw;
	}
`;

export const Img = styled.div<{ src: string }>(
	({ src }) => `
  content: '';
  height: 10.64vw;
  width: 10.64vw;
  background: url(${src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-top: 0.5vw;
  margin-left: 0.5vw;

  @media (max-width: 1023px) {
    height: 21.6vw;
    width: 21.6vw;
    margin-top: 1vw;
    margin-left: 1vw;
	}
`
);

export const Title = styled.div`
	font-size: 2vw;
	font-family: BitterBold;
	margin-bottom: 0.5vw;
	word-spacing: 9999px;
	${appear}

	@media (max-width: 1023px) {
		word-spacing: unset;
		font-size: 5.3vw;
		margin-bottom: 3vw;
	}
`;

export const Years = styled.div`
	font-size: 1.2vw;
	font-family: Bitter;
	${appear}

	@media (max-width: 1023px) {
		font-size: 3.2vw;
		margin-bottom: 3vw;
	}
`;

export const Description = styled.div`
	font-size: 1.2vw;
	margin-top: 1vw;
	overflow: hidden;
	text-overflow: ellipsis;
	${appear}

	@media (max-width: 1023px) {
		font-size: 3.5vw;
	}
`;

export const Lang = styled.button`
	height: 3.5vw;
	width: 3.5vw;
	color: white;
	background: #94b0a6;
	border-top-left-radius: 100%;
	position: absolute;
	bottom: 0;
	right: 0;
	border: none;
	cursor: pointer;
	z-index: 50;

	div {
		font-family: Bold;
		font-size: 1vw;
		transform: rotate(-45deg);
		padding-top: 0.5vw;
		@media (max-width: 1023px) {
			font-size: 3.5vw;
		}
	}

	@media (max-width: 1023px) {
		height: 11vw;
		width: 11vw;
	}
`;
