import styled from "styled-components";

const Wrapper = styled.div`
	font-family: Bitter;
	font-size: 1.56vw;
	width: 100%;
	height: 5.1vw;
	text-align: left;
	margin-top: 6.8vw;
	margin-bottom: 6.8vw;

	@media (max-width: 1023px) {
		height: unset;
		font-size: 3vw;
	}
`;

export const Authorship = ({ page }: { page: "basics" | "stories" }) => {
	if (page === "basics") {
		return (
			<Wrapper>
				Illustrations by Philip Yarin. Published in Anna Shibarova and Alexander
				Yarin, <i>Davaj Pogovorim!</i> (Stuttgart: Schmetterling, 2018), and
				<i> Raz, dva, tri</i> (Milan: Hoepli, 2019)
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			Illustrations by Philip Yarin. Courtesy of Schmetterling Verlag,
			Stuttgart, and Russischverlag, Witten
		</Wrapper>
	);
};
