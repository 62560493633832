import styled from "styled-components";

export const Wrapper = styled.div`
	font-family: RobotoCondensed;
	font-size: 2.3vw;
	color: white;
	position: relative;
	min-height: 45vw;
	margin-left: -5.27vw;
  .title-literature {
		background: transparent;
		position: absolute;
		bottom: 5vw;
		left: 47.13vw;
		cursor: pointer;
	
`;

const Card = styled.div`
	position: absolute;
	background-size: cover;
	cursor: pointer;
	transition: transform 0.3s ease-in-out;

	:hover {
		transform: translateY(-4px);
		z-index: 100;
	}
	:hover:active {
		transform: translateY(-2px);
	}

	.title {
		background: transparent;
		position: absolute;
		cursor: pointer;
	}
`;

export const Basics = styled(Card)`
	background-image: url(img/nav-basics.png);
	left: 0;
	bottom: 3.7vw;
	min-width: 28.34vw;
	min-height: 32vw;

	.title {
		bottom: 1.5vw;
		left: 6.4vw;
	}
`;

export const Stories = styled(Card)`
	background-image: url(img/nav-stories.png);
	left: 15.3vw;
	top: 0;
	min-width: 27.1vw;
	min-height: 25.5vw;

	.title {
		bottom: 1.5vw;
		left: 6.4vw;
	}
`;

export const People = styled(Card)`
	background-image: url(img/nav-people.png);
	left: 31.37vw;
	bottom: 2.67vw;
	min-height: 31.5vw;
	min-width: 23.9vw;

	.title {
		bottom: 8vw;
		left: 1.4vw;
	}
`;

export const Literature = styled(Card)`
	background-image: url(img/nav-literature.png);
	left: 47.86vw;
	bottom: 0;
	min-height: 43.35vw;
	min-width: 37.6vw;

	.title {
		bottom: 5vw;
		left: 2.27vw;
	}
`;
