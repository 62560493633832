import styled from "styled-components";

export const Wrapper = styled.div(
	({ imgSrc }: { imgSrc: string }) => `
	box-sizing: border-box;
	width: 15vw;
	height: 23.4vw;
	border: none;
	border-radius: 0.94vw;
	padding: 1.7vw;
  background: url(${imgSrc});
  background-color: white;
  background-size: auto 11.5vw;
  background-position: left top 1.4vw;
  background-repeat: no-repeat;
  position: relative;
  margin: auto;

  &.has-video {
    cursor: pointer;
  }

  @media (max-width: 1023px) {
    min-width: 70.1vw;
    min-height: 106.6vw;
    padding: 9vw;
    background-size: auto 56vw;
    background-position: left top 1.4vw;
	  border-radius: 4.3vw;
  }
`
);

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	height: 13.6vw;
	margin-bottom: 1.38vw;
	justify-content: space-between;

	@media (max-width: 1023px) {
		height: 50vw;
		margin-bottom: 5vw;
	}
`;

export const Letter = styled.div`
	font-family: Bold;
	font-size: 4.2vw;
	@media (max-width: 1023px) {
		font-size: 17.6vw;
	}
`;

export const Word = styled.div`
	font-size: 2.2vw;
	@media (max-width: 1023px) {
		font-size: 10vw;
	}
`;

export const Example = styled.div`
	font-size: 1.06vw;
	@media (max-width: 1023px) {
		font-size: 4.5vw;
	}
`;

export const PlayImg = styled.div`
	height: 2.96vw;
	width: 2.96vw;
	background: url(img/play.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	cursor: pointer;
	position: absolute;
	bottom: 1vw;
	right: 1vw;
	@media (max-width: 1023px) {
		height: 12vw;
		width: 12vw;
		bottom: 10vw;
		right: 10vw;
	}
`;
