import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	margin-right: 0;
	color: white;
	width: 100%;

	&.basics {
		max-height: 21.5vw;
	}
`;

export const Logo = styled.div`
	font-family: Sirotica;
	font-size: 6vw;
	display: flex;
	justify-content: space-between;
	width: 100%;

	a {
		text-decoration: none;
		color: white;
	}
	@media (orientation: landscape) {
		font-size: 6vh;
	}
`;

export const Right = styled.div`
	font-family: Regular;
	font-size: 3.7vw;

	a {
		color: white;
		text-decoration: none;
	}

	a.active {
		font-family: Bold;
	}

	@media (max-width: 1023px) {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 5.5vw;
	}
`;
