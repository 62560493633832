import styled from "styled-components";
import { appear } from "../../constants";

export const Wrapper = styled.div`
	min-height: 100vh;
	min-width: 100vw;
	padding: 6.8vw 16vw;
	background: #bec8cb;
	${appear}
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	font-family: Bitter;
	font-size: 2.4vw;
`;

export const Grid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-row-gap: 2.4vw;
	grid-column-gap: 1.7vw;
	margin-bottom: 3.4vw;
`;

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const ColBooks = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

export const SearchInput = styled.div`
	align-self: center;
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 24vw;
	height: 3vw;

	border-radius: 2vw;
	background: #ffffff;
	padding-left: 1.4vw;
	padding-right: 1.3vw;

	& > input {
		width: 85%;
		height: 100%;
		border: none;
		outline: none;
		background: transparent;
		font-size: 1.4vw;
		font-family: Bitter;
	}

	& > input::placeholder {
		color: #575757;
	}

	& > img {
		height: 1.6vw;
	}

	& > img:hover {
		cursor: pointer;
	}
`;

export const SearchResults = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2vw;

	& > h2 {
		font-size: 2.2vw;
		font-family: BitterBold;
	}
`;