import styled from "styled-components";
import { appear } from "../../constants";

export const Wrapper = styled.div`
	min-height: 100vh;
	min-width: 100vw;
	padding: 6.8vw;
	background: #b9c5bf;
	${appear}

	.person-card-wrapper {
		margin-bottom: 20px;
		margin-left: auto;
		margin-right: auto;
	}
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	font-family: Bitter;
	font-size: 4.5vw;
`;
