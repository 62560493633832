import { useEffect, useState, useCallback, useMemo } from "react";
import { TagCategory } from "./types";

export const useClickOutside = (
	ref: React.RefObject<HTMLElement>,
	onClickOutsideClick: () => void
) => {
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				ref &&
				ref.current &&
				event &&
				!ref.current.contains(event.target as Node)
			) {
				onClickOutsideClick();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, onClickOutsideClick]);
};

export const useTags = <T extends { tags?: string[], id?: number }>(
	taggedItems: T[],
	showAllByDefault?: boolean
) => {
	const [tags, setTags] = useState<string[]>([]);
	const [selectedTags, setSelectedTags] = useState<string[]>([]);

	useEffect(() => {
		if (taggedItems.length === 0 || tags.length !== 0) return;
		const tagsNext: Set<string> = new Set();
		taggedItems.forEach(({ tags }) => {
			if (tags && Array.isArray(tags) && tags.length > 0) {
				tags.forEach((tag) => {
					tagsNext.add(tag);
				});
			}
		});
		if (tagsNext.size > 0) {
			setTags(Array.from(tagsNext));
		}
	}, [taggedItems, tags]);

	const handleTagClick = useCallback(
		(tag: string) => {
			if (selectedTags.includes(tag)) {
				setSelectedTags([...selectedTags].filter((el) => el !== tag));
			} else {
				setSelectedTags([...selectedTags, tag]);
			}
		},
		[selectedTags]
	);

	const itemsVisible: typeof taggedItems = useMemo(() => {
		if (selectedTags.length === 0) return showAllByDefault ? taggedItems : [];

		return taggedItems.filter(({ tags }) => {
			return !!tags && tags.some((tag) => selectedTags.includes(tag));
		});
	}, [taggedItems, selectedTags, showAllByDefault]);

	const visibleItemsIds = useMemo(() => {
		const ids = new Set<number>();
		itemsVisible.forEach(el => ids.add(el.id ?? 0));
		return ids;
	}
	, [itemsVisible]);

	return { tags, selectedTags, itemsVisible, handleTagClick, setSelectedTags, visibleItemsIds };
};

export const useScript = (url: string) => {
	useEffect(() => {
		const script = document.createElement("script");

		script.src = url;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, [url]);
};

export const useTagCategories = <T extends { tags?: string[] }>(
	tagCategories: TagCategory[],
	taggedItems: T[],
	showAllByDefault?: boolean
) => {
	const { tags, selectedTags, itemsVisible, handleTagClick, setSelectedTags, visibleItemsIds } = useTags(
		taggedItems,
		showAllByDefault
	);
	const [selectedCategories, setSelectedCategories] = useState<{
		[title: string]: boolean;
	}>({});

	useEffect(() => {
		if (tagCategories.length > 0) {
			const selectedNext: { [title: string]: boolean } = {};
			tagCategories.forEach((cat) => {
				if (cat.selected) {
					selectedNext[cat.title] = true;
				}
			});
			setSelectedCategories(selectedNext);
		}
	}, [tagCategories]);

	const handleCategoryClick = useCallback(
		(id: string) => {
			const selected = selectedCategories[id];
			const categoriesNext = {
				...selectedCategories,
				[id]: !selected,
			};

			setSelectedCategories(categoriesNext);

			if (!!selected) {
				const removingCategory = tagCategories.find((el) => el.title === id);
				if (!removingCategory) return;
				const removingTags = removingCategory.tags;
				const selectedTagsNext = selectedTags.filter(
					(tag) => !(removingTags ?? []).includes(tag)
				);

				setSelectedTags(selectedTagsNext);
			}
		},
		[selectedCategories, tagCategories, selectedTags, setSelectedTags]
	);

	const tagsInCategories: string[] = useMemo(
		() =>
			tags.filter((tag) =>
				tagCategories
					.filter(({ title }) => selectedCategories[title])
					.map(({ tags }) => tags ?? [])
					.flat()
					.includes(tag)
			).sort((a, b) => a.localeCompare(b)),
		[selectedCategories, tagCategories, tags]
	);

	return {
		tagsInCategories,
		selectedCategories,
		handleCategoryClick,
		selectedTags,
		itemsVisible,
		handleTagClick,
		visibleItemsIds,
	};
};