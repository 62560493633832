import { Link } from "react-router-dom";
import { Rights, SocialMedia } from "../../components";
import { Wrapper, Logo, Right } from "./navigation-mobile.styled";
import { BurgerMenu } from "./burger-menu";

export const NavigationMobile = ({
	page,
	className,
	footer,
}: {
	page: string;
	className?: string;
	footer?: boolean;
}) => {
	return (
		<Wrapper
			className={`navigation ${className ?? ""}`}
			style={{
				marginTop: footer ? "15vw" : "0",
			}}
		>
			{!footer && <BurgerMenu />}
			<Logo className="logo">
				<Link to="/">
					into <br />
					russian
				</Link>
			</Logo>
			{footer && <SocialMedia />}
			{!footer && page === "main" && (
				<Right style={{ fontFamily: "RobotoCondensed", lineHeight: "140%" }}>
					Discover the Real Russian Language <br />
					Listen Watch Read Learn: From the Basics to Tolstoy
				</Right>
			)}
			{footer && (
				<Right>
					<Link className={page === "basics" ? "active" : ""} to="/basics">
						First Steps
					</Link>
					<Link className={page === "stories" ? "active" : ""} to="/stories">
						Stories
					</Link>
					<Link className={page === "people" ? "active" : ""} to="/people">
						People
					</Link>
					<Link
						className={page === "literature" ? "active" : ""}
						to="/literature"
					>
						Literature
					</Link>
					<Link className={page === "about" ? "active" : ""} to="/about">
						About
					</Link>
					<Link className={page === "about" ? "active" : ""} to="/updates">
						Updates
					</Link>
				</Right>
			)}
			{footer && <Rights />}
		</Wrapper>
	);
};
