import styled from "styled-components";

export const Wrapper = styled.div`
	min-height: 100vh;
	width: 100vw;
	background: #d0dbe0;

	.navigation {
		padding: 1.7vw 6.8vw;
		@media (orientation: landscape) and (max-width: 1023px) {
			padding: 1.7vh 6.8vh;
		}

		.logo {
			margin-top: 0.5vw;

			@media (max-width: 1023px) {
				margin-top: 20px;

				@media (orientation: landscape) {
					margin-top: 0.5vh;
					margin-top: 6vh;
				}
			}
		}
	}

	div.paragraph {
		width: 100%;
		margin: 10px 0;
		padding-left: 10px;
		color: #7f7f7f;
		border-left: 15px solid transparent;
		position: relative;

		&.active,
		&:hover {
			border-left: 15px solid #faebd7;
		}
		@media (max-width: 1023px) {
			&:hover {
				border-left: 15px solid transparent;
			}
		}
	}

	span.active {
		color: black;
	}
`;

export const Reader = styled.div`
	width: 100vw;
	padding: 0.5vw 16vw;
	background: white;
	font-size: 18px;
	line-height: 20px;
	min-height: calc(100vh - 3.6vw);

	@media (max-width: 1023px) {
		padding: 0.5vw;
		min-height: calc(100vh - 22.5vw);
	}
`;

export const EpisodeText = styled.div`
	width: 100%;
	max-width: 800px;
	@media (max-width: 1023px) {
		padding-bottom: 30vh;
	}
`;

export const Row = styled.div`
	display: flex;
`;

export const Controls = styled.div`
	display: flex;
	align-items: center;
	background: white;
	position: sticky;
	top: 0px;
	z-index: 100;
	padding-bottom: 10px;
	padding-top: 10px;

	@media (max-width: 1023px) {
		position: sticky;
		padding-top: 20px;
		padding-bottom: 10px;
		top: 0px;
	}
`;

export const Icons = styled.div`
	display: flex;
	margin-left: 25px;
`;

export const Title = styled.div`
	font-family: Bold;
	font-size: 18px;
	line-height: 28px;
	margin-left: 25px;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}

	@media (max-width: 1023px) {
		display: none;
	}
`;

export const TitleMobile = styled.div`
	display: none;

	@media (max-width: 1023px) {
		margin-top: 10px;
		margin-left: 25px;
		margin-right: 25px;
		font-size: 18px;
		line-height: 22px;
		display: block;
	}
`;

export const Icon = styled.img`
	height: 40px;
	width: 40px;
	margin-right: 4px;

	svg {
		fill: #606060;
	}

	:hover {
		color: #de8a16;
	}
`;

export const Dict = styled.div`
	margin-left: 20px;
	padding-top: 20px;
	min-width: 200px;
	width: 300px;
	font-family: Regular;

	ul {
		margin-left: 20px;
	}

	@media (max-width: 1023px) {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		overflow-y: auto;

		max-height: 30vh;
		width: 100vw;
		background: white;
		padding-top: 10px;
		padding-left: 25px;
		padding-bottom: 10px;
		margin-left: 0;

		border-top: 1px solid #7f7f7f;

		&.opened {
			height: 30vh;
		}
	}
`;

export const DictInner = styled.div`
	@media (min-width: 1023px) {
		position: sticky;
		top: 70px;
	}
`;

export const Lemma = styled.div`
	font-family: Bold;
	font-size: 20px;
	line-height: 24px;
	display: flex;
`;

export const Senses = styled.ul``;

export const Sense = styled.li``;

export const MobileDict = styled.div``;

export const Close = styled.div`
	margin-left: auto;
	margin-right: 20px;
	font-family: Regular;
	@media (min-width: 1024px) {
		display: none;
	}
`;

export const Sentence = styled.span`
	span {
		display: inline-block;
	}
`;

export const Selector = styled.div`
	position: absolute;
	left: -15px;
	top: 0;
	height: 100%;
	border-left: 15px solid transparent;

	&:hover {
		border-left: 15px solid #eacda5;
	}
`;

export const SentenceWrapper = styled.span`
	${Selector}:hover + ${Sentence} {
		span.word {
			text-decoration: underline;
		}
	}
`;

export const BottomLinks = styled.div`
	display: flex;
	padding: 0 24px;
`;

export const Link = styled.button`
	color: #50a41d;
	text-decoration: underline;
	cursor: pointer;
	margin-top: 20px;
	margin-bottom: 20px;
	background-color: unset;
	border: unset;
	font-size: 18px;

	&.next {
		margin-left: auto;
	}
`;
