import {
	createContext,
	useContext,
	useState,
	useCallback,
	useEffect,
} from "react";
import { useHistory } from "react-router-dom";

import { VideoModalContext, VideoModalContextData, VideoItem } from "../types";

const defaultContextData: VideoModalContextData = {
	videoId: "",
	isOpened: false,
};

const defaultContext = {
	...defaultContextData,
	showVideo: () => {
		throw new Error("showVideo not implemented");
	},

	hideVideo: () => {
		throw new Error("hideVideo not implemented");
	},
	next: () => {
		throw new Error("next not implemented");
	},
	prev: () => {
		throw new Error("prev not implemented");
	},
};

const Context = createContext<VideoModalContext>(defaultContext);
Context.displayName = "VideoModalContext";

export const useVideoModal = () => useContext(Context);

export const VideoModalProvider: React.FC = ({ children }) => {
	const [videoData, setVideoData] = useState(defaultContextData);
	const [currentIdx, setCurrentIdx] = useState(0);
	const [videoList, setVideoList] = useState<VideoItem[]>([]);
	const { push } = useHistory();

	const showVideo = useCallback((videos: VideoItem[], i: number) => {
		const video = videos[i];
		if (!video) return;

		setVideoList(videos);
		setCurrentIdx(i);
	}, []);

	const pushNewSearch = useCallback((value: string) => {
		const { search } = window.location;
		const newSearch = new URLSearchParams(search);
		// there is only one param in search
		const param = newSearch.keys().next().value;
		if (!param) return

		newSearch.set(param, value);
		push({search: newSearch.toString()})
	}, [push]);

	const next = useCallback(() => {
		const nextIdx = currentIdx + 1 >= videoList.length ? 0 : currentIdx + 1;
		setCurrentIdx(nextIdx);
		pushNewSearch(nextIdx.toString());
	}, [videoList, currentIdx, pushNewSearch]);

	const prev = useCallback(() => {
		const nextIdx = currentIdx < 1 ? videoList.length - 1 : currentIdx - 1;
		setCurrentIdx(nextIdx);
		pushNewSearch(nextIdx.toString());
	}, [videoList, currentIdx, pushNewSearch]);

	useEffect(() => {
		const video = videoList[currentIdx];
		if (!video) return;
		const { videoId, transcript } = video;

		setVideoData({
			videoId,
			isOpened: true,
			transcript,
		});
	}, [currentIdx, videoList]);

	const hideVideo = useCallback(() => {
		setVideoData(defaultContext);
		push(window.location.pathname.toString());
	}, [push]);

	return (
		<Context.Provider
			value={{ ...videoData, showVideo, hideVideo, next, prev }}
		>
			{children}
		</Context.Provider>
	);
};
