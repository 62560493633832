import React, { useCallback, useEffect, useRef } from 'react'
import { SearchResult, isAuthorResult, isBookResult } from '../../pages/literature/hooks';
import styled from 'styled-components';
import { SearchInput } from '../../pages/literature/literature.styled';
import { useHistory } from 'react-router-dom';
import { useApi } from '../../contexts';
import { getFirstEpisode, isEpisodeClickable } from '../../utils';

export default function SearchDropdown({
  searchResults,
  searchInputRef,
  setShowSearchDropdown,
  search,
  setSearch,
  onSearch
}: {
  searchResults: SearchResult[];
  searchInputRef: React.RefObject<HTMLDivElement>;
  setShowSearchDropdown: (show: boolean) => void;
  search: string;
  setSearch: (search: string) => void;
  onSearch: () => void;
}) {
  const inputCopyRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const { episodes } = useApi();

  const repositionDropdown = useCallback(() => {
    const current = searchInputRef.current;
    if (!current) return;
    const { top, left } = current.getBoundingClientRect();
    const dropdown = document.getElementById('modal-upper-layer');
    if (!dropdown) return;
    dropdown.style.top = `${top}px`;
    dropdown.style.left = `${left}px`;
    dropdown.style.display = 'flex';
  }, [searchInputRef]);

  useEffect(() => {
    repositionDropdown();

    window.addEventListener('scroll', repositionDropdown);

    return () => {
      window.removeEventListener('scroll', repositionDropdown);
    }
  }, [searchResults, searchInputRef, repositionDropdown]);

  useEffect(() => {
    inputCopyRef.current?.focus();

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Enter') {
        setShowSearchDropdown(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [setShowSearchDropdown]);

  return (
    <Wrapper
      onClick={() => {
        setShowSearchDropdown(false);
      }}
    >
      <div id='modal-upper-layer'>
        <SearchInput
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <input
            ref={inputCopyRef}
            type="text" 
            placeholder="Search"
            maxLength={100}
            value={search || ""}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch();
              }
            }}
          />
          <img
            src="/img/search-icon.svg"
            alt=""
            onClick={onSearch}
          />
        </SearchInput>
        <Dropdown
          id='search-dropdown'
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {searchResults.map((result) => (
            <>
              {isAuthorResult(result) && (
                <li
                  key={result.data.id}
                  onClick={() => {
                    history.push(`/author/${result.data.id}`);
                  }}
                >
                  <img src="/img/author-icon.svg" alt="" />
                  <p>{result.data.nameRu}</p>
                </li>
              )}

              {isBookResult(result) && (
                <li
                  key={result.data.id}
                  onClick={() => {
                    const firstEpisode = getFirstEpisode(episodes, result.data.id);
                    isEpisodeClickable(firstEpisode) && history.push(`/episode/${firstEpisode.id}`);
                  }}
                >
                  <img src="/img/text-icon.svg" alt="" />
                  <p>{result.data.title}</p>
                </li>
              )}
            </>
          ))}
        </Dropdown>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 100;

  & > div {
    position: absolute;
    display: hidden;
    flex-direction: column;
    gap: 4px;
  }
`;

const Dropdown = styled.ul`
  width: 24vw;
  max-height: 70vh;
  border-radius: 2vw;
  list-style: none;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  gap: 1.25vw;

  overflow-y: scroll;

  & > li {
    min-height: 2.2vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.25vw;
    padding: 4px 1.7vw;
    font-size: 1.2vw;
  }

  & > li:first-of-type {
    margin-top: 2vw;
  }

  & > li:last-of-type {
    margin-bottom: 2vw;
  }

  & > li:hover {
    background-color: #DAEEF1;
    cursor: pointer;
  }

  & > li > img {
    height: 2vw;
  }

`;