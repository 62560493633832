import { Story, VideoItem } from "../../types";
import { useVideoModal } from "../../contexts";

import {
	Wrapper,
	ImgContainer,
	Title,
	Description,
	PlayImg,
} from "./story-card.styled";

export const StoryCard = ({
	titleRu,
	titleEn,
	description,
	imgSrc,
	videoSrc,
	transcript,
	videos,
}: Story & { videos: VideoItem[] }) => {
	const { showVideo } = useVideoModal();
	const videoId = videos.findIndex((el) => el.videoId === videoSrc);
	return (
		<Wrapper
			className={`story-card-wrapper ${!!videoSrc && " has-video"}`}
			onClick={() => {
				if (!videoSrc) return;

				showVideo(videos, videoId);
				const url = new URL(window.location.toString());
				url.searchParams.set("story", videoId.toString());
				window.history.pushState(null, "", url.toString());
			}}
		>
			<ImgContainer imgSrc={imgSrc} />
			<Title>{titleRu}</Title>
			<Title>{titleEn}</Title>
			<Description>{description}</Description>
			{!!videoSrc && <PlayImg />}
		</Wrapper>
	);
};
