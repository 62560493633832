import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Author } from "../../types";

import {
	Wrapper,
	Header,
	ImgContainer,
	Title,
	Years,
	Description,
	Img,
	Lang,
} from "./author-card.styled";

export const AuthorCard = ({
	id,
	nameRu,
	nameEn,
	years,
	descriptionRu,
	descriptionEn,
	imgSrc,
}: Author) => {
	const history = useHistory();
	const [isEnShown, setIsEnShown] = useState(true);

	return (
		<Wrapper className="author-card-wrapper" id={`author-${id}`}>
			<Header onClick={() => history.push(`/author/${id}`)}>
				<ImgContainer>
					<Img src={imgSrc} />
				</ImgContainer>
				<div className="author-name">
					{isEnShown && <Title>{nameEn}</Title>}
					{!isEnShown && <Title>{nameRu}</Title>}

					<Years>{years}</Years>
				</div>
			</Header>
			{isEnShown && (
				<Description onClick={() => history.push(`/author/${id}`)}>
					{descriptionEn}
				</Description>
			)}
			{!isEnShown && (
				<Description onClick={() => history.push(`/author/${id}`)}>
					{descriptionRu}
				</Description>
			)}
			<Lang onClick={() => setIsEnShown(!isEnShown)}>
				<div>{isEnShown ? "RUS" : "EN"}</div>
			</Lang>
		</Wrapper>
	);
};
