import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Cross } from "./cross.svg";
import { ReactComponent as Prev } from "./prev.svg";
import { ReactComponent as Next } from "./next.svg";
import { ReactComponent as Hide } from "./hide.svg";
import { ReactComponent as Expand } from "./expand.svg";
import { ReactComponent as Document } from "./document.svg";
import { ReactComponent as Up } from "./up.svg";
import { ReactComponent as Down } from "./down.svg";
import { useVideoModal } from "../../contexts";

const Wrapper = styled.div`
	height: auto;
	min-width: 40vw;
	width: 40vw;
	background: white;
	margin-left: 0.7vw;

	display: flex;
	flex-direction: column;

	&.hidden {
		min-width: 8vw;
		width: 8vw;
	}

	@media (max-width: 1023px) {
		min-width: 50vw;
		width: 50vw;
		min-height: 100vh;
	}

	@media (max-width: 550px) {
		width: 100vw;
		min-width: 100vw;
		min-height: 45vh;
		margin: 0;
		&.hidden {
			width: 100vw;
			min-width: 100vw;
			min-height: 60px;
			max-height: 60px;
		}
	}
`;

const Header = styled.div`
	background: #7ab467;
	color: white;
	cursor: pointer;
	font-size: min(1.2vw, 24px);
	line-height: min(1.2vw, 24px);

	padding: 0 min(1.8vw, 36px);
	min-height: min(4vw, 76px);

	display: flex;
	align-items: center;

	svg {
		max-width: min(1.2vw, 24px);
		max-height: min(1.2vw, 24px);
		margin-right: min(1.2vw, 10px);
		@media (max-width: 1023px) {
			max-width: min(2vw, 24px);
			max-height: min(2vw, 24px);
		}
	}

	@media (max-width: 1023px) {
		font-size: min(2vw, 24px);
		line-height: min(2vw, 24px);
		min-height: min(6vw, 76px);
	}

	@media (max-width: 550px) {
		font-size: min(3vw, 24px);
		line-height: min(3vw, 24px);
		min-height: min(9vw, 76px);
		position: fixed;
		width: 100vw;
		top: 0;
		left: 0;
		right: 0;
	}
`;

export const Logo = styled.div`
	font-family: Sirotica;
	font-size: min(1.2vw, 24px);
	line-height: min(1.2vw, 24px);
	margin-left: auto;
	margin-top: min(0.2vw, 4px);

	&.hidden {
		display: none;

		@media (max-width: 550px) {
			display: unset;
		}
	}

	@media (max-width: 1023px) {
		font-size: min(2vw, 24px);
		line-height: min(2vw, 24px);
	}
	@media (max-width: 550px) {
		font-size: min(3vw, 24px);
		line-height: min(3vw, 24px);
	}
`;

const Content = styled.div`
	padding: min(1.8vw, 36px);
	overflow: auto;

	&.hidden {
		display: none;
	}
`;

const Controls = styled.div`
	display: flex;
	padding-top: min(1.2vw, 24px);
	padding-bottom: min(1.2vw, 24px);
	padding-left: min(1.8vw, 36px);
	padding-right: min(1.8vw, 36px);
	background: black;
	font-size: min(1.2vw, 24px);
	line-height: min(1.2vw, 24px);
	color: white;
	justify-content: flex-start;
	align-items: center;
	margin-top: auto;

	.hide {
	}

	&.hidden {
		flex-direction: column;
		justify-content: flex-start;
		height: 100%;
	}

	@media (max-width: 1023px) {
		font-size: min(2vw, 24px);
		line-height: min(2vw, 24px);
	}
	@media (max-width: 550px) {
		flex-direction: row;
		font-size: min(4vw, 24px);
		line-height: min(4vw, 24px);
		min-height: 60px;
		max-height: 60px;

		&.hidden {
			flex-direction: row;
			min-height: 60px;
			max-height: 60px;
			margin-top: 0;
		}

		&.expanded {
			margin-top: auto;
		}
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;

	&.hidden {
		flex-direction: column;
		justify-content: center;
		margin-bottom: min(1.8vw, 36px);

		button {
			margin-bottom: min(1.8vw, 36px);
			width: 100%;
			margin-right: 0;
			&:first-of-type {
				margin-top: 20px;
				margin-bottom: 10px;
			}
			@media (max-width: 550px) {
				margin-bottom: 0;
				margin-right: 0;
				&:first-of-type {
					margin-top: 0;
					margin-bottom: 0;
					margin-right: 10px;
				}
			}
		}

		@media (max-width: 1023px) {
			.label {
				display: none;
			}
		}
		@media (max-width: 550px) {
			flex-direction: row;
			align-items: center;
			.label {
				display: unset;
			}

			&.expanded {
				button {
					margin-right: 10px;
				}
			}
		}
	}

	@media (max-width: 1023px) {
		&.expanded:first-of-type {
			margin-right: 20px;
		}
	}

	@media (max-width: 550px) {
		&.hidden:first-of-type {
			margin-right: 20px;
		}
	}

	@media (min-width: 1024px) {
		&.expanded {
			button {
				margin-left: 20px;
				margin-right: 12px;
			}
		}
	}
`;

const Button = styled.button`
	background: #000;
	border-radius: min(0.5vw, 8px);
	border: 3px solid #fff;
	color: #fff;
	margin-right: 0.5vw;
	padding-left: min(1.2vw, 24px);
	padding-right: min(1.2vw, 24px);
	padding-top: min(0.6vw, 12px);
	padding-bottom: min(0.6vw, 12px);
	cursor: pointer;

	.logo {
		max-height: 18px;
		max-width: 18px;
	}
	@media (max-width: 1023px) {
		margin-right: 10px;
		border: 1px solid #fff;
		.logo {
			border-radius: 2px;
			max-height: 10px;
			max-width: 10px;
			margin-left: 5px;
			margin-right: 5px;
		}
	}
	@media (max-width: 550px) {
		.logo {
			border-radius: 2px;
			max-height: 10px;
			max-width: 10px;
			margin-left: 5px;
			margin-right: 5px;
		}
	}
`;

const HideButton = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;

	&.hidden {
		margin-top: auto;
		flex-direction: column;
		margin-bottom: min(1.8vw, 36px);

		.expand {
			margin-top: min(1.8vw, 36px);
			margin-bottom: min(1.8vw, 36px);
		}

		.document {
			@media (max-width: 1023px) {
				max-width: 32px;
			}
		}
	}
	.hide-icon {
		@media (max-width: 1023px) {
			margin-left: 10px;
		}
	}

	@media (max-width: 550px) {
		display: none;
		flex-direction: row;

		&.hidden {
			flex-direction: row-reverse;
		}
	}
	@media (max-width: 1023px) {
		&.expanded {
			margin-left: auto;
		}
	}

	@media (min-width: 1024px) {
		margin-left: auto;
		.hide-icon {
			margin-left: 20px;
		}
	}
`;

const UpDownButton = styled.div`
	display: none;

	@media (max-width: 550px) {
		display: unset;
		margin-left: auto;
		svg {
			margin-right: 10px;
		}
	}
`;

export const Transcript = ({
	videoId,
	transcript,
	handleClose,
}: {
	videoId: string;
	transcript?: string;
	handleClose: () => void;
}) => {
	const { next, prev } = useVideoModal();
	const [expanded, setExpanded] = useState(false);
	return (
		<Wrapper className={expanded ? "expanded" : "hidden"}>
			<Header className="header" onClick={handleClose}>
				<Cross />
				Exit
				<Logo className={expanded ? "expanded" : "hidden"}>into russian</Logo>
			</Header>
			<Content className={expanded ? "expanded" : "hidden"}>
				<div dangerouslySetInnerHTML={{ __html: transcript ?? "" }} />
			</Content>
			<Controls className={`controls ${expanded ? "expanded" : "hidden"}`}>
				<ButtonWrapper
					className={expanded ? "expanded" : "hidden"}
					onClick={() => prev()}
				>
					<Button>
						<Prev className="logo" />
					</Button>
					<div className="label">Previous</div>
				</ButtonWrapper>
				<ButtonWrapper
					className={`${expanded ? "expanded" : "hidden"}`}
					onClick={() => next()}
				>
					<Button>
						<Next className="logo" />
					</Button>
					<div className="label">Next</div>
				</ButtonWrapper>
				<HideButton
					className={`hide ${expanded ? "expanded" : "hidden"}`}
					onClick={() => setExpanded(!expanded)}
				>
					{!expanded && <Document className="document" />}
					{!expanded && <Expand className="expand" />}
					{expanded ? "Hide" : "Expand"}
					{expanded && <Hide className="hide-icon" />}
				</HideButton>
				<UpDownButton onClick={() => setExpanded(!expanded)}>
					<Up className="up-icon" />
					<Down className="down-icon" />
				</UpDownButton>
			</Controls>
		</Wrapper>
	);
};
