import { useApi } from "../../contexts";
import { Navigation, PersonCard, Head, Tags } from "../../components";
import { Wrapper, Col } from "./people-mobile.styled";
import { getVideos } from "../../utils";
import { useTagCategories } from "../../hooks";

export const PeopleMobilePage = () => {
	const { persons, personsTagCategories } = useApi();
	const { selectedTags, handleTagClick, tagsInCategories, visibleItemsIds } = useTagCategories(
		personsTagCategories,
		persons,
		true
		);
	const videos = getVideos(persons);

	return (
		<Wrapper>
			<Head
				title="Into Russian - People"
				description="Videos and podcasts about people and their lives listen watch read Russian"
				canonical="people"
			/>
			<Navigation page="people" />
			<Col style={{ marginTop: "3.4vw", marginBottom: "3.4vw" }}>
				<div style={{ marginBottom: "1vw", fontFamily: "BitterBold" }}>Peoplе. From Novice (A1+) to Advanced (C).</div>
				<div>
					Videos and podcasts about people and their lives
				</div>
			</Col>
			<Tags
				tags={[...tagsInCategories]}
				page="persons"
				selected={selectedTags}
				handleTagClick={handleTagClick}
				style={{ marginTop: "3.4vw", marginBottom: "3.4vw" }}
			/>
			{persons.map((person) => (
				<PersonCard
					key={person.id}
					videos={videos}
					{...person}
					isHidden={!visibleItemsIds.has(person.id)}
					/>
			))}
			<Navigation page="people" footer />
		</Wrapper>
	);
};
