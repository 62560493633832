import styled, { keyframes } from "styled-components";
import { useHistory } from "react-router-dom";
import { useApi } from "../../contexts";
import { Book as BookType } from "../../types";
import { isEpisodeClickable } from "../../utils";

const ANIMATION_DURATION = 1000;

const reveal = keyframes`
  0% {
    width: 0px;
    height: 500px;
  } 

  100% {
    width: 86vw;
    height: 500px;
  }
`;

const appear = keyframes`
  0% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
	min-height: 500px;
	margin-bottom: 12px;
	border-radius: 8px;
	display: flex;
	font-size: 14px;
	animation-name: ${reveal};
	animation-duration: ${ANIMATION_DURATION}ms;
	background: linear-gradient(
			180deg,
			rgba(131, 131, 131, 0.184) 0%,
			rgba(173, 169, 169, 0.2) 21.35%,
			rgba(255, 255, 255, 0.2) 34.9%,
			rgba(158, 158, 158, 0.052) 54.69%,
			rgba(0, 0, 0, 0.2) 100%
		),
		#ffffff;
`;

const Cover = styled.div`
	content: "";
	min-width: 26px;
	border-radius: 8px;
	border-left: 3px solid #9a9a9a;
	background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(0, 0, 0, 0.072) 6.77%,
			rgba(255, 255, 255, 0.2) 12.5%,
			rgba(0, 0, 0, 0) 19.79%,
			rgba(251, 251, 251, 0.148958) 34.9%,
			rgba(0, 0, 0, 0.01) 45.83%,
			rgba(255, 255, 255, 0.124) 56.77%,
			rgba(0, 0, 0, 0) 64.06%,
			rgba(255, 255, 255, 0.138) 78.65%,
			rgba(0, 0, 0, 0) 89.06%,
			rgba(255, 255, 255, 0.116) 96.88%,
			rgba(44, 44, 44, 0.116) 100%
		),
		#ededed;
`;

const Episodes = styled.div`
	flex-grow: 1;
	width: 100%;
	padding: 24px;
	display: flex;
	flex-direction: column;
	position: relative;

	animation: ${appear};
	animation-duration: ${ANIMATION_DURATION}ms;
`;

const Corner = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	height: 60px;
	width: 60px;
	border-radius: 8px;

	img {
		margin-left: 10px;
		margin-top: 14px;
		max-height: 60px;
		max-width: 60px;
	}
`;

export const Episode = styled.div`
	margin-top: 12px;
`;

export const MoreEpisodes = styled.div`
	font-family: Bold;
	display: flex;
	margin-top: 20px;

	div {
		color: #50a41d;
		border: 1px solid #50a41d;
		border-radius: 50%;
		height: 20px;
		width: 20px;
		margin-right: 10px;
		text-align: center;

		:first-of-type {
			margin-left: 20px;
		}
	}
`;

export const Number = styled.div`
	font-family: Bold;
`;

export const Content = styled.div``;

export const Read = styled.div`
	font-family: Bold;
	color: #50a41d;
	text-decoration: underline;
`;

export const Title = styled.div`
	font-family: Regular;
	font-size: 12px;
	margin-top: auto;
	padding-top: 20px;
`;

export const BookOpenedMobile = ({ id, title }: BookType) => {
	const { episodes } = useApi();
	const history = useHistory();

	const episodesByBook = episodes
		.filter(({ bookId }) => bookId === id)
		.sort((a, b) => a.order - b.order);
	const isEpisodeNumberVisible = episodesByBook.length > 1;

	const moreEpisodes = episodesByBook.slice(2);

	return (
		<Container id={`opened-book-${id}`}>
			<Cover />
			<Episodes>
				{episodesByBook.slice(0, 2).map((episode) => {
					return (
						<Episode
							key={episode.id}
							style={{
								cursor: isEpisodeClickable(episode) ? "pointer" : "default",
							}}
							onClick={() =>
								isEpisodeClickable(episode) &&
								history.push(`/episode/${episode.id}`)
							}
						>
							{isEpisodeNumberVisible &&
								(episode.title ? (
									<Number>{episode.title}</Number>
								) : (
									<Number>Эпизод {episode.order}</Number>
								))}
							{episode.contentFormatted &&
							episode.contentFormatted.length > 0 ? (
								<Content
									dangerouslySetInnerHTML={{ __html: episode.contentFormatted }}
								/>
							) : (
								<Content>{episode.content}</Content>
							)}
							{isEpisodeClickable(episode) && <Read>Читать &gt;&gt;</Read>}
						</Episode>
					);
				})}
				{moreEpisodes.length > 0 && (
					<MoreEpisodes>
						Эпизоды
						{moreEpisodes.map((ep) => (
							<div
								onClick={() =>
									isEpisodeClickable(ep) && history.push(`/episode/${ep.id}`)
								}
							>
								{ep.order}
							</div>
						))}
					</MoreEpisodes>
				)}
				<Title>{title}</Title>
				<Corner>
					<img src="/img/mobile-book-corner.png" alt="" />
				</Corner>
			</Episodes>
		</Container>
	);
};
