import styled from "styled-components";
import { appear } from "../../constants";

export const Wrapper = styled.div`
	min-height: 100vh;
	min-width: 100vw;
	padding: 6.8vw 16vw;
	background: #bec8cb;
	${appear}
`;

export const Header = styled.div`
	display: flex;
	margin-top: 4.2vw;
	margin-bottom: 2.1vw;

	.author-name {
		margin-left: 1.3vw;
	}
`;

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`;

export const ImgContainer = styled.div`
	background-color: white;
	height: 5.54vw;
	width: 5.54vw;
	min-height: 5.54vw;
	min-width: 5.54vw;
	border-radius: 50%;
`;

export const Img = styled.div<{ src: string }>(
	({ src }) => `
  content: '';
  height: 4.62vw;
  width: 4.62vw;
  background: url(${src});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-top: 0.3vw;
  margin-left: 0.3vw;
`
);

export const Title = styled.div`
	font-size: 2.4vw;
	font-family: BitterBold;
	margin-bottom: 0.5vw;
`;

export const Years = styled.div`
	font-size: 1vw;
	font-family: BitterBold;
`;
