import styled from "styled-components";

export const Tabs = styled.div`
	display: flex;
	
	@media (max-width: 1023px) {
		width: 100vw;
		height: 8vw;
		margin-bottom: 5vw;
		margin-left: -6.8vw;
		margin-right: -6.8vw;
		padding: 0 6.8vw;
		z-index: 9;
		position: sticky;
		top: 21.4vw;

		background: linear-gradient(rgba(204, 213, 206, 1) 0%, rgba(219, 225, 217, 0) 150%);
	}
`;

export const Tab = styled.button`
	font-family: BitterSemibold;
	color: black;
	font-size: 1.5vw;
	font-weight: bold;
	cursor: pointer;
	display: flex;
	outline: none;
	border: 1px solid black;
	justify-content: center;
	align-items: center;
	background-color: #DBE1D9;
	border-top-left-radius: 0.94vw;
	border-top-right-radius: 0.94vw;
	padding: 0.4vw 1.5vw 0.4vw 1.5vw;

	&:nth-last-of-type(2) {
		margin: 0 0.6vw;
	}

	&:hover {
		background-color: #49FF7F;
	}

	&:active {
		background-color: #49FF7F;
	}

	&.selected {
		background-color: #7CDB99;
		cursor: unset;
	}

	@media (max-width: 1023px) {
		width: 100%;
		height: 6.75vw;
		font-size: 3.7vw;
		background-color: #CED6CF;
		border: 0.77px solid black;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom-left-radius: 2vw;
		border-bottom-right-radius: 2vw;
		padding: 1vw 0;
		margin: 0;

		&:first-of-type {
			margin-right: 0.6vw;
		}

		&:last-of-type {
			margin-left: 0.6vw;
		}

		&:hover {
			background-color: #49FF7F;
		}

		&.selected {
			background-color: #7CDB99;
		}
	}
`;
