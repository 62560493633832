import styled from "styled-components";
import { appear } from "../../constants";

export const Wrapper = styled.div`
	min-height: 100vh;
	min-width: 100vw;
	padding: 6.8vw 10vw;
	background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0) 16.67%,
			rgba(255, 255, 255, 0.62) 33.85%,
			rgba(189, 200, 214, 0.62) 50%,
			rgba(243, 243, 243, 0.37) 73.44%
		),
		#bcc5b9;

	@media (max-width: 1023px) {
		overflow-x: hidden;
		padding: 6.8vw;
	}

	${appear}
`;
