import { useRef, useState } from "react";

import { Overlay, Wrapper } from "./modal.styled";

import { useClickOutside } from "../../hooks";

export const Modal: React.FC<{
	title: string;
	url: string;
	isOpened: boolean;
	transcript?: string;
	handleClose: () => void;
}> = ({ children, isOpened, handleClose }) => {
	const [isTranscriptOpened, setIsTranscriptOpened] = useState(false);

	const wrapperRef = useRef(null);
	useClickOutside(wrapperRef, () => {
		if (isTranscriptOpened) return;
		handleClose();
	});

	const transcriptRef = useRef(null);
	useClickOutside(transcriptRef, () => {
		setIsTranscriptOpened(false);
	});

	if (!isOpened) return null;

	return (
		<Overlay>
			<Wrapper className="modal-wrapper" ref={wrapperRef}>
				{children}
			</Wrapper>
		</Overlay>
	);
};
