import { useEffect, useMemo } from "react";
import { useApi, useDevice } from "../../contexts";
import { useLocation } from "react-router-dom";
import { Navigation, PersonCard, Head, Tags } from "../../components";
import { Wrapper, Col, Grid } from "./people.styled";
import { PeopleMobilePage } from "./people-mobile";
import { useVideoModal } from "../../contexts";
import { getVideos } from "../../utils";

import { useTagCategories } from "../../hooks";

export const PeoplePage = () => {
	const { persons, personsTagCategories } = useApi();
	const { selectedTags, handleTagClick, tagsInCategories, visibleItemsIds } = useTagCategories(
		personsTagCategories,
		persons,
		true
	);
	const { isMobile } = useDevice();
	const { isOpened, showVideo } = useVideoModal();
	const { search } = useLocation();
	const videos = getVideos(persons);

	const videoId = useMemo(() => {
		return new URLSearchParams(search).get("person");
	}, [search]);

	useEffect(() => {
		if (!videoId || isOpened || !window.location.search.includes("person"))
			return;
		if (Number.isNaN(+videoId)) return;
		if (~videos.findIndex((el) => el.videoId === videoId)) return;

		setTimeout(() => {
			showVideo(videos, +videoId);
		}, 500);
	}, [showVideo, videoId, videos, isOpened]);

	if (isMobile) {
		return <PeopleMobilePage />;
	}

	return (
		<Wrapper>
			<Head
				title="Into Russian - People"
				description="Videos and podcasts about people and their lives listen watch read Russian"
				canonical="people"
			/>
			<Navigation page="people" />
			<Col style={{ marginTop: "3.4vw", marginBottom: "3.4vw" }}>
				<div style={{ marginBottom: "1vw", fontFamily: "BitterBold" }}>
					Peoplе. From Novice (A1+) to Advanced (C)
				</div>
				<div>
					Videos and podcasts about people and their lives
				</div>
			</Col>
			<Tags
				tags={[...tagsInCategories]}
				page="persons"
				selected={selectedTags}
				handleTagClick={handleTagClick}
				style={{ marginTop: "3.4vw", marginBottom: "3.4vw" }}
			/>
			<Grid>
				{persons.map((person) => {
					return <PersonCard
						key={person.id}
						videos={videos}
						{...person}
						isHidden={!visibleItemsIds.has(person.id)}
						/>;
				})}
			</Grid>
			<Navigation page="people" footer />
		</Wrapper>
	);
};
