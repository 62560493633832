import { FC, useRef } from "react";
import SlickSlider from "react-slick";
import { useHistory } from "react-router-dom";
import { useDevice } from "../../contexts";

import {
	Wrapper,
	Header,
	Title,
	SubTitle,
	WatchAll,
	SquaresImg,
} from "./slider.styled";

export const Slider: FC<{
	title?: string;
	subtitle?: string;
	level?: string;
	to: string;
	slidesToShow?: number;
}> = ({ title, subtitle, level, to, slidesToShow = 4, children }) => {
	const history = useHistory();
	const { isMobile } = useDevice();
	const ref = useRef(null);
	//TODO: add move to slider element
	// const handleOnClick = (i: number) => {
	// 	if (!ref) return;
	// 	if (!ref.current) return;
	// 	//@ts-ignore
	// 	ref.current.slickGoTo(i);
	// };
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToScroll: 1,
		slidesToShow: isMobile ? 1 : slidesToShow,
		arrows: !isMobile,
		centerMode: isMobile,
		variableWidth: false,
		centerPadding: "25px",
	};
	return (
		<Wrapper>
			<Header>
				{title && <Title onClick={() => history.push(to)}>{title}</Title>}
				{!isMobile && subtitle && (
					<SubTitle onClick={() => history.push(to)}>
						{level} {subtitle}
					</SubTitle>
				)}
				<WatchAll onClick={() => history.push(to)}>
					See all
					<SquaresImg />
				</WatchAll>
			</Header>
			{isMobile && subtitle && (
				<SubTitle onClick={() => history.push(to)}>
					{level} {subtitle}
				</SubTitle>
			)}
			<SlickSlider ref={ref} {...settings}>
				{children}
			</SlickSlider>
			{/* <button onClick={() => handleOnClick(3)}>click</button> */}
		</Wrapper>
	);
};
