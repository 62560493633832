export const appear = `
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

animation: 0.6s ease-out 0s 1 appear;`;
