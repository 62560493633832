import styled from "styled-components";

export const Wrapper = styled.div`
	min-height: 100vh;
	min-width: 100vw;
	background: #d0dbe0;

	.navigation {
		padding: 1.7vw 6.8vw;
	}
`;
