import { useState } from "react";
import { Navigation, AboutCard, CreatorCard, Head } from "../../components";
import { useApi } from "../../contexts";
import {
	Wrapper,
	Card,
	Grid,
	GridCreators,
	Lang,
	TeamTitle,
	TeamSubtitle,
	PageTitle,
} from "./about.styled";

export const AboutPage = () => {
	const { aboutPageContent, aboutPageCards, aboutPageCreators } = useApi();
	const [isEnShown, setIsEnShown] = useState(true);

	const teamTitle =
		aboutPageContent.length > 0 &&
		(isEnShown
			? aboutPageContent[0].teamTitleEn
			: aboutPageContent[0].teamTitleRu);

	const teamSubtitle =
		aboutPageContent.length > 0 &&
		(isEnShown
			? aboutPageContent[0].teamSubtitleEn
			: aboutPageContent[0].teamSubtitleRu);

	const thanksTitle =
		aboutPageContent.length > 0 &&
		(isEnShown
			? aboutPageContent[0].thanksTitleEn
			: aboutPageContent[0].thanksTitleRu);

	const thanksSubtitle =
		aboutPageContent.length > 0 &&
		(isEnShown
			? aboutPageContent[0].thanksSubtitleEn
			: aboutPageContent[0].thanksSubtitleRu);

	return (
		<Wrapper>
			<Head
				title="Into Russian - About"
				description="Selected texts from Russian writers for learning Russian, with audio, annotations and a glossary. A road map to Russian literature with a thematic catalogue."
				canonical="about"
			/>
			<Navigation page="about" />
			<PageTitle>
				<div style={{ marginBottom: "1vw", fontFamily: "BitterBold" }}>About</div>
				<div>
					Learning Russian? Up for a challenge? Want to have fun? Then this
					website is for you!
				</div>
			</PageTitle>
			<Grid>
				{aboutPageCards.map((card) => {
					return <AboutCard key={card.id} {...card} />;
				})}
			</Grid>
			<Card>
				<TeamTitle>{teamTitle}</TeamTitle>
				<TeamSubtitle>{teamSubtitle}</TeamSubtitle>
				<GridCreators>
					{aboutPageCreators
						.filter(({ isStudent }) => !isStudent)
						.map((creator) => (
							<CreatorCard
								key={creator.id}
								{...creator}
								isEnShown={isEnShown}
							/>
						))}
				</GridCreators>
				<TeamTitle>{thanksTitle}</TeamTitle>
				<TeamSubtitle>{thanksSubtitle}</TeamSubtitle>
				<GridCreators>
					{aboutPageCreators
						.filter(({ isStudent }) => !!isStudent)
						.map((creator) => (
							<CreatorCard
								key={creator.id}
								{...creator}
								isEnShown={isEnShown}
							/>
						))}
				</GridCreators>
				<Lang onClick={() => setIsEnShown(!isEnShown)}>
					<div>{isEnShown ? "RUS" : "EN"}</div>
				</Lang>
			</Card>

			<Navigation page="about" footer />
		</Wrapper>
	);
};
