import React from "react";
import { Helmet } from "react-helmet";

export const Head: React.FC<{
	title: string;
	description: string;
	canonical?: string;
}> = (props) => {
	const { canonical, title, description } = props;

	const website = "https://www.intorussian.slavistik.uni-muenchen.de";
	const lang = "en";
	const canonicalUrl = canonical ? `${website}/${canonical}` : website;

	const shema = `
  {
    "@context": "http://schema.org",
  "@type": "WebSite",
  "url": "${canonicalUrl}",
  "name": "${title}",
  "description": "${description}",
  "image": "${website}/logo192.png"
  }`;

	return (
		<>
			<Helmet>
				<html lang={lang} />
				<title>{title}</title>
				<meta name="description" content={description} />
				<link rel="canonical" href={canonicalUrl} />
				<link
					rel="alternate"
					href={canonical ? `${website}/${canonical}` : `${website}`}
				/>

				{/*OG*/}
				<meta property="og:title" content={title} />
				<meta property="og:type" content="website" />
				<meta property="og:url" content={canonicalUrl} />
				<meta property="og:image" content={`${website}/logo192.png`} />
				<meta property="og:description" content={description} />
				<meta property="og:site_name" content={title} />

				{/*TWITTER*/}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content={canonicalUrl} />
				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
				<meta name="twitter:image:src" content={`${website}/logo192.png`} />
				<meta name="twitter:domain" content={website} />

				{props.children}

				<script type="application/ld+json">{shema}</script>
			</Helmet>
		</>
	);
};
