import { useState } from "react";
import { useHistory } from "react-router-dom";
import { AboutPageCard } from "../../types";

import {
	Wrapper,
	ImgContainer,
	Title,
	Description,
	Lang,
} from "./about-card.styled";

export const AboutCard = ({
	titleRu,
	titleEn,
	descriptionRu,
	descriptionEn,
	href,
	imgSrc,
}: AboutPageCard) => {
	const [isEnShown, setIsEnShown] = useState(true);
	const history = useHistory();

	return (
		<Wrapper
			className="about-card-wrapper"
			onClick={() => {
				if (!href || href.length < 1) return;

				history.push(href);
			}}
		>
			<ImgContainer
				src={imgSrc}
				alt={titleEn}
				onClick={() => {
					// showVideo("People", "/people", videoSrc, transcript);
				}}
			/>
			{isEnShown && <Title>{titleEn}</Title>}
			{!isEnShown && <Title>{titleRu}</Title>}
			{isEnShown && <Description>{descriptionEn}</Description>}
			{!isEnShown && <Description>{descriptionRu}</Description>}
			<Lang
				onClick={(e) => {
					e.stopPropagation();
					setIsEnShown(!isEnShown);
				}}
			>
				<div>{isEnShown ? "RUS" : "EN"}</div>
			</Lang>
		</Wrapper>
	);
};
