import styled from "styled-components";

export const Wrapper = styled.div`
	margin-top: 13.6vw;

	.slick-slider {
		margin-left: 7vw;
		margin-right: 7vw;
	}

	.slick-arrow {
		background: #ececec;
		box-shadow: 0px 0px 26.8078px rgba(0, 0, 0, 0.2);
		width: 3.66vw;
		height: 3.66vw;
		border-radius: 50%;
	}

	.slick-prev,
	.slick-next {
		background-repeat: no-repeat;
		background-position: center;
		background-size: 25%;

		top: calc(50% - 13px);

		:before {
			content: "";
		}
	}

	.slick-next {
		background-image: url(img/right.svg);
		right: -6vw;
	}

	.slick-prev {
		background-image: url(img/left.svg);
		left: -6vw;
	}

	.basic-card-wrapper,
	.story-card-wrapper {
		margin-left: auto;
		margin-right: auto;
	}

	@media (max-width: 1023px) {
		.slick-slider {
			margin-left: unset;
			margin-right: unset;
		}

		.slick-list {
			overflow: visible;
		}

		.slick-slide:not(.slick-current) {
			opacity: 0.3;
		}
	}
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1.7vw;

	@media (max-width: 1023px) {
		margin-left: 0;
		margin-right: 0;
	}
`;

export const Title = styled.div`
	font-family: Bitter;
	font-size: 2.2vw;
	line-height: 2.2vw;
	cursor: pointer;

	@media (max-width: 1023px) {
		font-size: 5.9vw;
	}
`;

export const SubTitle = styled.div`
	font-family: Bitter;
	font-size: 1.45vw;
	margin-left: 1.7vw;
	vertical-align: top;
	cursor: pointer;

	b {
		font-family: Bitter;
	}

	@media (max-width: 1023px) {
		font-size: 4.3vw;
		margin-top: 7.7vw;
		margin-bottom: 7.7vw;
		margin-left: 0;
	}
`;

export const WatchAll = styled.div`
	display: flex;
	align-items: center;
	font-family: RobotoCondensedLight;
	font-size: 1.45vw;
	line-height: 150%;
	font-weight: 300;
	margin-left: auto;
	cursor: pointer;
	user-select: none;
	text-decoration: underline;
	@media (max-width: 1023px) {
		display: none;
	}
`;

export const SquaresImg = styled.div`
	background-image: url(img/squares.svg);
	width: 1.45vw;
	height: 1.45vw;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	margin-left: 0.5vw;
`;
