import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useApi } from "../../contexts";
import { useTags } from "../../hooks";
import { Tags } from "..";

import { BookClosed } from "./book-closed";
import { BookOpened } from "./book-opened";
import styled from "styled-components";
import { sortByLastWord } from "../../utils";

export const Bookshelf = ({
	authorId,
	showAllByDefault,
}: {
	authorId?: number;
	showAllByDefault?: boolean;
}) => {
	const { search } = useLocation();
	const { books, tagCategories } = useApi();
	const { tags, selectedTags, itemsVisible, handleTagClick } =
		useTags(
			books.filter(
				({ visible, ...book }) =>
					visible && (authorId ? book.authorId === authorId : true)
			),
			showAllByDefault
		);

	const [selectedCategories, setSelectedCategories] = useState<{
		[title: string]: boolean;
	}>({});
	const [selectedBook, setSelectedBook] = useState<number | undefined>(
		undefined
	);

	const bookId = useMemo(() => {
		return new URLSearchParams(search).get("book");
	}, [search]);

	useEffect(() => {
		if (!bookId || typeof selectedBook !== "undefined") return;
		if (Number.isNaN(+bookId)) return;
		if (itemsVisible.findIndex((el) => el.id === +bookId) < 0) return;

		setSelectedBook(+bookId);

		setTimeout(() => {
			document
				?.getElementById(`opened-book-${bookId}`)
				?.scrollIntoView({ behavior: "smooth", block: "center" });
		}, 1500);
	}, [bookId, selectedBook, itemsVisible]);

	useEffect(() => {
		if (tagCategories.length > 0) {
			const selectedNext: { [title: string]: boolean } = {};
			tagCategories.forEach((cat) => {
				if (cat.selected) {
					selectedNext[cat.title] = true;
				}
			});
			setSelectedCategories(selectedNext);
		}
	}, [tagCategories]);

	const tagsInCategories: string[] = useMemo(
		() =>
			tags.filter((tag) =>
				tagCategories
					.filter(({ title }) => selectedCategories[title])
					.map(({ tags }) => tags ?? [])
					.flat()
					.includes(tag)
			),

		[selectedCategories, tagCategories, tags]
	);

	return (
		<>
			{typeof authorId === "undefined" && (
				<TagsGroup>
					{tagCategories.map((category) => (
						<Tags
							key={category.title}
							tags={tagsInCategories.filter((tag) =>
								category.tags?.includes(tag)
							).sort((a, b) => a.localeCompare(b))}
							page="literature"
							selected={selectedTags}
							handleTagClick={handleTagClick}
						/>
					))}
				</TagsGroup>
			)}

			<div>
				{itemsVisible
					.filter(({ visible }) => visible)
					.sort((a, b) => sortByLastWord(b.title, a.title))
					.map((book) =>
						selectedBook === book.id ? (
							<BookOpened key={book.id} {...book} />
						) : (
							<BookClosed
								{...book}
								key={book.id}
								onClick={() => {
									setSelectedBook(book.id);
									const url = new URL(window.location.toString());
									url.searchParams.set("book", book.id.toString());
									window.history.pushState(null, "", url.toString());
								}}
							/>
						)
					)}
			</div>
		</>
	);
};

const TagsGroup = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6vw;
	margin-bottom: 3.4vw;

	@media (max-width: 1023px) {
		gap: 3.7vw;
		margin-bottom: 5.6vw;
	}
`;