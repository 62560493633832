import { useParams, useHistory } from "react-router-dom";
import { useApi } from "../../contexts";
import { Navigation, Bookshelf } from "../../components";
import {
	Wrapper,
	Header,
	Title,
	Years,
	ImgContainer,
	Img,
} from "./author-mobile.styled";

export const AuthorMobilePage = () => {
	const { authors } = useApi();
	const { id } = useParams<{ id: string }>();
	const author = authors.find((author) => Number(id) === author.id);
	const history = useHistory();

	if (!author) return null;

	return (
		<Wrapper>
			<Navigation page="literature" />
			<Header
				onClick={() => {
					history.push(`/literature#author-${id}`);
					setTimeout(() => {
						document
							?.getElementById(`author-${id}`)
							?.scrollIntoView({ behavior: "smooth", block: "center" });
					}, 0);
				}}
			>
				<ImgContainer>
					<Img src={author.imgSrc} />
				</ImgContainer>
				<div className="author-name">
					<Title>{author.nameRu}</Title>
					<Years>{author.years}</Years>
				</div>
			</Header>

			<Bookshelf authorId={author.id} showAllByDefault />
			<Navigation page="literature" footer />
		</Wrapper>
	);
};
