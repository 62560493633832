import {
	useState,
	useEffect,
	useMemo,
	createContext,
	useContext,
	FC,
} from "react";

enum Screen {
	mobile = "mobile",
	desktop = "desktop",
}

const BREAKPOINT = 1024;

const defaultContext = {
	screen: Screen.desktop,
	isMobile: false,
};

const Context = createContext(defaultContext);

export const useDevice = () => useContext(Context);

export const DeviceProvider: FC = ({ children }) => {
	const [width, setWidth] = useState(window.innerWidth);

	useEffect(() => {
		const updateWidth = () => {
			setWidth(window.innerWidth);
		};
		window.addEventListener("resize", updateWidth);

		return () => {
			window.removeEventListener("resize", updateWidth);
		};
	}, []);

	const screen = useMemo(() => {
		let result = Screen.desktop;

		if (width < BREAKPOINT) {
			result = Screen.mobile;
		}

		return result;
	}, [width]);

	const isMobile = useMemo(() => screen === Screen.mobile, [screen]);

	return (
		<Context.Provider value={{ screen, isMobile }}>{children}</Context.Provider>
	);
};
