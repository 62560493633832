import styled from "styled-components";

export const Wrapper = styled.div`
	box-sizing: border-box;
	width: 15vw;
	height: 23.4vw;
	border: none;
	border-radius: 0.94vw;
	padding: 1.7vw;
	background-color: white;
	position: relative;

	&.has-video {
		cursor: pointer;
	}

	@media (max-width: 1023px) {
		min-width: 70.1vw;
		min-height: 106.6vw;
		padding: 9vw;
		border-radius: 4.3vw;
	}
`;

export const ImgContainer = styled.div(
	({ imgSrc }: { imgSrc: string }) => `
  background: url(${imgSrc});
  background-color: white;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
	height: 53%;
  margin-bottom: 1vw;
  
	@media (max-width: 1023px) {
		height: 60%;
	}
`
);

export const Title = styled.div`
	font-size: 1.2vw;
	font-family: Bold;
	@media (max-width: 1023px) {
		font-size: 5vw;
	}}
`;

export const Description = styled.div`
	font-size: 1.2vw;
	margin-top: 0.5vw;
	@media (max-width: 1023px) {
		font-size: 5vw;
	}
`;

export const PlayImg = styled.div`
	height: 2.96vw;
	width: 2.96vw;
	background: url(img/play.png);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	cursor: pointer;
	position: absolute;
	bottom: 1vw;
	right: 1vw;
	@media (max-width: 1023px) {
		height: 12vw;
		width: 12vw;
		bottom: 6.5vw;
		right: 6.5vw;
	}
`;
