import styled from "styled-components";
import { appear } from "../../constants";

export const Wrapper = styled.div`
	min-height: 100vh;
	min-width: 100vw;
	padding: 6.8vw 16vw;
	background: #b9c5bf;
	${appear}

	@media (max-width: 1023px) {
		padding: 6.8vw;
	}
`;

export const Header = styled.div`
	font-family: Bitter;
	font-size: 3.35vw;
	margin-top: 5.35vw;
	margin-bottom: 1.7vw;

	@media (max-width: 1023px) {
		font-size: 4.5vw;
		margin-bottom: 5vw;
		margin-left: 5vw;
	}
`;

export const Card = styled.div`
	background: white;
	width: 100%;
	border-radius: 1vw;
	padding: 2.7vw 2.3vw;

	@media (max-width: 1023px) {
		border-radius: 5vw;
		padding: 5vw;
	}
`;

export const Title = styled.div`
	font-size: 1.2vw;
	font-family: Bold;
	margin-bottom: 0.5vw;

	@media (max-width: 1023px) {
		font-size: 4.5vw;
	}
`;

export const Description = styled.div`
	font-size: 1vw;

	@media (max-width: 1023px) {
		font-size: 2.5vw;
	}
`;

export const Row = styled.div`
	display: flex;
	width: 100%;

	.desc {
		height: 12.1vw;

		@media (max-width: 1023px) {
			height: unset;
		}
	}

	@media (max-width: 1023px) {
		flex-direction: column;
		margin-bottom: 10vw;
		:last-of-type {
			margin-bottom: 0;
		}
	}
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 3.9vw;
`;

export const Img = styled.div(
	({ src }: { src: string }) => `
  background: url(${src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 1vw;
	border-radius: 0.94vw;
  content: '';
  min-width: 21.4vw;
  height: 12.1vw;
  margin-right: 1.7vw;

  @media (max-width: 1023px) {
		min-width: unset;
    padding-top: 50%;
    width: 100%;
    border-radius: 5vw;
	}
`
);

export const CreatedAt = styled.div`
	font-size: 1.2vw;
	font-family: Bold;
	margin-bottom: 0.5vw;
	margin-top: auto;

	@media (max-width: 1023px) {
		font-size: 2.5vw;
		margin-top: 3vw;
	}
`;

export const UpdateType = styled.div`
	font-size: 2vw;
	font-family: Bitter;
	margin-bottom: 1.7vw;

	span {
		text-decoration: underline;
	}

	@media (max-width: 1023px) {
		font-size: 4.5vw;
	}
`;
