import styled from "styled-components";

const Wrapper = styled.div<{
	textColor: string;
  selectedTextColor: string;
  hoverTextColor: string;
  clickTextColor: string;
  selectedBackgroundColor: string;
  hoverBackgroundColor: string;
  clickBackgroundColor?: string;
	selected?: boolean;
}>(
	({ 
    textColor,
    selectedTextColor,
    hoverTextColor,
    clickTextColor,
    selectedBackgroundColor,
    hoverBackgroundColor,
    clickBackgroundColor,
    selected
   }) => `
	font-size: 1.4vw;
  letter-spacing: 0.1vw;
  line-height: 1.5vw;
	font-family: bold;
	color: ${selected ? selectedTextColor : textColor};
	display: flex;
	align-items: center;
	justify-content: center;
	height: 3.17vw;
	border-radius: 2vw;
	padding: 0 2vw;
  padding-right: ${selected ? "3.7vw" : "2vw"};
	background: ${selected ? selectedBackgroundColor : 'transparent'};
  border: 1px solid ${selected ? selectedTextColor : textColor};
	margin-right: 1vw;
	margin-bottom: 1vw;
	cursor: pointer;
	transition: all 0.2s;
  user-select: none;
  position: relative;

	:hover {
    background: ${hoverBackgroundColor};
    color: ${hoverTextColor};
	}
  :hover:active {
    background: ${clickBackgroundColor};
    color: ${clickTextColor};
  }

  :before, :after {
    ${selected ? "content: '';" : ""}
    position:absolute;
    width: 1.7vw;
    height: 0.3vw;
    background-color: ${selectedTextColor};
    border-radius: 2px;
    right: 1vw;

    @media (max-width: 1023px) {
      right: 2vw;
    }
  }

  :before{
    transform:rotate(45deg);
  }
  :after{
    transform:rotate(-45deg);
  }

  @media (max-width: 1023px) {
    font-size: 12px;
    line-height: 16px;
    padding: 0 12px;
    padding-right: ${selected ? "22px" : "12px"};
    height: 22px;
    border-radius: 12px;

    :hover {
      transform: unset;
      box-shadow: unset;
      background: ${selected ? selectedBackgroundColor : 'transparent'};
      color: ${selected ? selectedTextColor : textColor};
    }

    :hover:active {
      transform: unset;
      box-shadow: unset;
      background: ${selected ? selectedBackgroundColor : 'transparent'};
      color: ${selected ? selectedTextColor : textColor};
    }
  }
`
);

export const Tag = ({
	tag,
	onClick,
	selected,
  textColor,
  selectedTextColor,
  hoverTextColor,
  clickTextColor,
  selectedBackgroundColor,
  hoverBackgroundColor,
  clickBackgroundColor,
}: {
	tag: string;
	onClick: () => void;
	selected?: boolean;
  textColor: string;
  selectedTextColor: string;
  hoverTextColor: string;
  clickTextColor: string;
  selectedBackgroundColor: string;
  hoverBackgroundColor: string;
  clickBackgroundColor: string;
}) => {
	return (
		<Wrapper
			selected={selected}
			textColor={textColor}
			selectedTextColor={selectedTextColor}
      hoverTextColor={hoverTextColor}
      clickTextColor={clickTextColor}
      selectedBackgroundColor={selectedBackgroundColor}
      hoverBackgroundColor={hoverBackgroundColor}
      clickBackgroundColor={clickBackgroundColor}
			className="tag"
			onClick={() => onClick()}
		>
			{tag}
		</Wrapper>
	);
};
