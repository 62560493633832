import { useCallback, useState, useEffect, useRef, useMemo } from "react";
import { useApi } from "../../contexts";
import { playAudio } from "../../utils";
import { LETTERS } from "./letters";

class Mutex {
	locked: boolean;
	constructor() {
		this.locked = false;
	}

	lock() {
		this.locked = true;
	}

	unlock() {
		this.locked = false;
	}
}
const mutex = new Mutex();

export const useBasics = () => {
	const { basics, basicsGrammar, basicsContacts } = useApi();
	const [currentLetter, setCurrentLetter] = useState("");
	const loadedImagesCount = useRef(0);
	const [finishedLoading, setFinishedLoading] = useState(false);

	useEffect(() => {
		return () => {
			mutex.unlock();
		};
	}, []);

	const play = useCallback(
		async (letter: string, idx: number) => {
			if (!mutex.locked) return;
			setCurrentLetter(letter);
			await playAudio(`/letters/${idx}.mp3`);
		},
		[setCurrentLetter]
	);

	const startPlaying = useCallback(async () => {
		try {
			for (let i = 0; i < LETTERS.length; i++) {
				await play(LETTERS[i], i);
			}
		} catch (e) {
			console.error(e);
		} finally {
			setCurrentLetter("");
		}
	}, [play]);

	const stopPlaying = useCallback(() => {
		mutex.unlock();
	}, []);

	const handleLetterClick = useCallback(
		async (letter: string, idx: number) => {
			try {
				if (!mutex.locked) {
					mutex.lock();
					await play(letter, idx);
					mutex.unlock();
					setCurrentLetter("");
				}
			} catch (e) {
				setCurrentLetter("");
				mutex.unlock();
			}
		},
		[play]
	);

	const handleButtonClick = useCallback(() => {
		if (!currentLetter) {
			mutex.lock();
			startPlaying();
		} else {
			mutex.unlock();
		}
	}, [currentLetter, startPlaying]);

	const totalImages = useMemo(() => basics.length + basicsGrammar.length + basicsContacts.length, [basics, basicsGrammar, basicsContacts]);

	const onImageLoad = useCallback((imgSrc: string) => {
		if (basics.length === 0 || basicsGrammar.length === 0 || basicsContacts.length === 0) return;

		const image = new Image();
		image.onload = () => {
			loadedImagesCount.current += 1;
			if (loadedImagesCount.current === totalImages) {
				setFinishedLoading(true);
			}
		};
		image.src = imgSrc;
	}, [totalImages, basics.length, basicsGrammar.length, basicsContacts.length]);

	return {
		basics,
		basicsGrammar,
		basicsContacts,
		currentLetter,
		stopPlaying,
		handleButtonClick,
		handleLetterClick,
		finishedLoading,
		onImageLoad,
	};
};
