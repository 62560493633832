import styled from "styled-components";

export const Wrapper = styled.div`
	box-sizing: border-box;
	width: 32.8vw;
	min-height: 18vw;
	border: none;
	border-radius: 0.94vw;
	padding: 0.5vw;
	background-color: white;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 1.2vw;
	margin-bottom: 2.3vw;

	@media (max-width: 1023px) {
		width: 100%;
		padding: 6.5vw;
		border-radius: 4.3vw;
	}
`;

export const Header = styled.div`
	display: flex;
	align-items: center;

	.author-name {
		margin-left: 2vw;
	}
`;

export const Title = styled.div`
	font-size: 2vw;
	font-family: BitterBold;
	margin-bottom: 0.5vw;

	@media (max-width: 1023px) {
		font-size: 4.5vw;
	}
`;

export const Subtitle = styled.div`
	font-size: 1vw;
	font-family: Bitter;
	@media (max-width: 1023px) {
		font-size: 2.5vw;
	}
`;

export const Episodes = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Episode = styled.div`
	margin-top: 1vw;

	@media (max-width: 1023px) {
	}
`;

export const Number = styled.div`
	font-family: Bold;
	text-decoration: underline;
`;

export const Content = styled.div``;
