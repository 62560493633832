import styled from "styled-components";

export const Wrapper = styled.div`
	font-family: RobotoCondensed;
	font-size: 3.9vw;
	color: white;
	min-height: 45vw;
	margin-top: 7.4vw;
`;

export const Basics = styled.div`
	background-image: url(img/nav-basics-mobile.png);
	background-size: cover;
	min-width: 41.3vw;
	min-height: 52vw;
	margin-left: -1.7vw;
	margin-bottom: 1.7vw;
	margin-top: 10vw;
	padding: 44vw 0 0 26vw;
	transition: all 0.2s ease;

	.title {
		background: transparent;
	}
`;

export const Stories = styled.div`
	background-image: url(img/nav-stories-mobile.png);
	background-size: cover;
	min-width: 45.6vw;
	min-height: 43.2vw;
	margin-left: -7.4vw;
	margin-right: 3.4vw;
	margin-bottom: 1.7vw;
	padding: 36vw 0 0 11vw;
	transition: all 0.2s ease;

	&:hover,
	&:active {
		transform: scale(1.1);
	}

	.title {
		background: transparent;
	}
`;

export const People = styled.div`
	background-image: url(img/nav-people-mobile.png);
	background-size: cover;
	min-height: 74vw;
	min-width: 40.5vw;
	padding: 2vw 0 0 4vw;
	transition: all 0.2s ease;

	&:hover,
	&:active {
		transform: scale(1.1);
	}

	.title {
		background: transparent;
	}
`;

export const Literature = styled.div`
	background-image: url(img/nav-literature-mobile.png);
	background-size: cover;
	min-height: 62.8vw;
	min-width: 41.3vw;
	padding: 2vw 0 0 25vw;
	transition: all 0.2s ease;

	&:hover,
	&:active {
		transform: scale(1.1);
	}

	.title {
		background: transparent;
	}
`;

export const Row = styled.div`
	display: flex;
	width: 100%;
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;
