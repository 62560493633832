import { useState } from "react";
import { useApi, useDevice } from "../../contexts";
import styled, { keyframes } from "styled-components";
import { Book as BookType } from "../../types";
import { BookClosedMobile } from "./book-closed-mobile";

const ANIMATION_DURATION = 3000;
const CB_DELAY = ANIMATION_DURATION - 300;

const animation = keyframes`
  0% {
	  height: 4.8vw;
  }

  25% {
	  height: 4.8vw;
		transform: rotate(30deg);
    margin-top: 20vw;
    margin-bottom: 20vw;
  }

  95% {
		transform: rotate(90deg);
    height: 68vw;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 39.06%, rgba(148, 149, 149, 0.2) 50%, rgba(255, 255, 255, 0.2) 50.01%), #FFFFFF;
  }

  100% {
		transform: rotate(90deg);
    height: 68vw;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 39.06%, rgba(148, 149, 149, 0.2) 50%, rgba(255, 255, 255, 0.2) 50.01%), #FFFFFF;
  }
`;

const hideLetters = keyframes`
  0% {
    opacity: 100%;
  }
  20% {
    opacity: 100%;
  }
  24% {
    opacity: 0%;
  }
  100% {
    opacity: 0%;
  }
`;

const Container = styled.div`
	height: 4.8vw;
	background: white;
	border-radius: 12px;
	padding: 10px 12px;
	margin-bottom: 12px;
	cursor: pointer;
	border-top: 0px solid red;

	&:nth-of-type(2n) {
		background-color: #dce8dd;
	}
	&:nth-of-type(3n) {
		background-color: #cbe3dd;
	}
	&:nth-of-type(4n) {
		background-color: #dce8dd;
	}

	@media (min-width: 1024px) {
		&.clicked {
			animation-name: ${animation};
			animation-duration: ${ANIMATION_DURATION}ms;
		}
	}

	@media (max-width: 1023px) {
		height: 24px;
		border-radius: 4px;
		padding: 4px 6px;
		margin-bottom: 6px;
	}
`;

const Inner = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	padding: 0 2.2vw;
	font-size: 2vw;
	border-left: 2px solid #c4c4c4;
	border-right: 2px solid #c4c4c4;

	@media (min-width: 1024px) {
		&.clicked {
			animation-name: ${hideLetters};
			animation-duration: ${ANIMATION_DURATION}ms;
		}
	}

	@media (max-width: 1023px) {
		font-size: 2vw;
		padding: 0px 1vw;
	}
`;

const Author = styled.div`
	font-family: Bitter;
	color: #807a7a;
`;
const Title = styled.div`
	&.smallerText {
		font-size: 1.5vw;

		@media (max-width: 1023px) {
			font-size: 10px;
		}
	}
`;

export const BookClosed = ({
	id,
	title,
	authorId,
	onClick,
}: BookType & { onClick: () => void }) => {
	const { authors } = useApi();
	const [clicked, setClicked] = useState("");
	const { isMobile } = useDevice();

	if (isMobile) {
		return (
			<BookClosedMobile title={title} authorId={authorId} onClick={onClick} />
		);
	}

	const author = authors.find((el) => el.id === authorId);

	return (
		<Container
			onClick={() => {
				if (isMobile) {
					onClick();
					return;
				}

				setClicked("clicked");
				setTimeout(() => {
					setClicked("");
					onClick();
				}, CB_DELAY);
			}}
			className={clicked}
		>
			<Inner className={clicked}>
				{author && <Author>{author.shortNameRu ?? author.nameRu}</Author>}
				<Title className={`${title.length > 40 ? "smallerText" : ""}`}>
					{title.substring(0, 50)}
					{title.length > 50 ? "..." : ""}
				</Title>
			</Inner>
		</Container>
	);
};
