import { useState } from "react";
import { Link } from "react-router-dom";
import { useVideoModal } from "../../contexts";
import { Logo } from "./navigation-mobile.styled";
import {
	Wrapper,
	Bars,
	Content,
	Row,
	Cross,
	CloseVideo,
	Overlay,
	List,
} from "./burger-menu.styled";

export const BurgerMenu = () => {
	const [isOpened, setIsOpened] = useState(false);
	const video = useVideoModal();

	return (
		<Overlay isOpened={isOpened}>
			<Wrapper className="burger-menu" isOpened={isOpened}>
				{!video.isOpened && (
					<Bars isOpened={isOpened} onClick={() => setIsOpened(true)}>
						<div className="bar1"></div>
						<div className="bar2"></div>
						<div className="bar3"></div>
					</Bars>
				)}
				{video.isOpened && <CloseVideo onClick={() => video.hideVideo()} />}
				<Content className="content" isOpened={isOpened}>
					<Row>
						<Logo>
							into <br />
							russian
						</Logo>
						<Cross onClick={() => setIsOpened(false)} />
					</Row>
					<Row style={{ marginTop: "5vw" }}>
						<Link to="/basics">
							<b>First Steps</b> A0+
						</Link>
					</Row>
					<List>
							<Row>
								<Link to="/basics#alphabet">Alphabet</Link>
							</Row>
							<Row>
								<Link to="/basics#grammar">Grammar</Link>
							</Row>
							<Row>
								<Link to="/basics#first-contact">First Contact</Link>
							</Row>
						</List>
					<Row>
						<Link to="/stories">
							<b>Stories</b> A1/A2+
						</Link>
					</Row>
					<Row>
						<Link to="/people">
							<b>People</b> A1+/C
						</Link>
					</Row>
					<Row>
						<Link to="/literature">
							<b>Literature</b> All Levels
						</Link>
					</Row>
					<Row>
						<Link to="/about">
							<b>About</b>
						</Link>
					</Row>
				</Content>
			</Wrapper>
		</Overlay>
	);
};
