import { Modal, YoutubeVideo } from "../";
import { useVideoModal } from "../../contexts";
import { Transcript } from "./transcript";
import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	/* height: 100vh; */
	width: 100vw;
	max-width: 95vw;
	max-height: 90vh;

	@media (max-width: 550px) {
		flex-direction: column;
		max-width: 100vw;
	}
	@media (max-width: 1023px) {
		max-width: 100vw;
	}
`;

export const VideoModal = () => {
	const { videoId, transcript, isOpened, hideVideo } = useVideoModal();

	return (
		<Modal
			title={""}
			url={""}
			isOpened={isOpened}
			transcript={transcript}
			handleClose={hideVideo}
		>
			<Wrapper className="video-wrapper">
				<YoutubeVideo videoId={videoId} />
				<Transcript
					transcript={transcript}
					videoId={videoId}
					handleClose={hideVideo}
				/>
			</Wrapper>
		</Modal>
	);
};
