import styled from "styled-components";
import { appear } from "../../constants";

export const Wrapper = styled.div`
	min-height: 100vh;
	min-width: 100vw;
	padding: 6.8vw;
	background: #dbe1d9;
	${appear}

	@media (max-width: 1023px) {
		.basic-card-wrapper {
			margin-bottom: 6.4vw;
		}

		.navigation.basics {
			width: 100vw;
			margin-top: -6.8vw !important;
			margin-left: -6.8vw;
			margin-right: -6.8vw;
			padding: 6.8vw 6.8vw 1.7vw 6.8vw;
			position: sticky;
			top: 0;
			z-index: 10;
			
			background: linear-gradient(rgba(219, 225, 217, 0) 0%, rgba(219, 225, 217, 1) 200%);
			background-color: #B9C5BF;
		}
	}
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	font-family: Bitter;
	font-size: 4.5vw;
`;

export const Row = styled.div`
	display: flex;
`;

export const Delimiter = styled.div`
	content: "";
	width: 100%;
	border-bottom: 1px solid #adbca8;
	margin-bottom: 6.4vw;
	margin-top: 6.4vw;
`;

export const Hidden = styled.div`
	content: "";
	display: hidden;
`;

export const Cards = styled.div`
	display: flex;
	flex-direction: column;
	gap: 6.4vw;
`;

export const Subtitle = styled.h3`
	margin-bottom: 5vw;
	font-family: BitterSemiBold;
	font-size: 6.4vw;
	color: #242424;
`;