import React from 'react'
import styled from 'styled-components'
import { SearchResult, isAuthorResult, isBookResult } from '../../pages/literature/hooks';
import { useHistory } from 'react-router-dom';
import { useApi } from '../../contexts';
import { getFirstEpisode, isEpisodeClickable } from '../../utils';

export default function SearchDropdown({
  searchResults,
}: {
  searchResults: SearchResult[];
}) {
  const { episodes } = useApi();
  const history = useHistory();
  return (
    <Dropdown>
      {searchResults.map((result) => (
          <>
            {isAuthorResult(result) && (
              <li
                key={result.data.id}
                onClick={() => {
                  history.push(`/author/${result.data.id}`);
                }}
              >
                <img src="/img/author-icon.svg" alt="" />
                <p>{result.data.nameRu}</p>
                <img src="/img/arrow.svg" alt="" />
              </li>
            )}

            {isBookResult(result) && (
              <li
                key={result.data.id}
                onClick={() => {
                  const firstEpisode = getFirstEpisode(episodes, result.data.id);
                    isEpisodeClickable(firstEpisode) && history.push(`/episode/${firstEpisode.id}`);
                }}
              >
                <img src="/img/text-icon.svg" alt="" />
                <p>{result.data.title}</p>
                <img src="/img/arrow.svg" alt="" />
              </li>
            )}
          </>
        ))}
    </Dropdown>
  )
}

const Dropdown = styled.ul`
  width: 100%;
  list-style: none;
  background-color: #fff;

  li {
    display: flex;
    align-items: center;
    gap: 4vw;
    padding: 3vw 6.4vw;
  }

  li img:last-child {
    margin-left: auto;
  }
`;
