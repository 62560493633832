import { useHistory, useLocation } from "react-router-dom";
import { Navigation } from "../../components";
import {
	Wrapper,
	EpisodeText,
	Row,
	Icon,
	Dict,
	Senses,
	Sense,
	Lemma,
	Icons,
	Title,
	Reader,
	Controls,
	Close,
	DictInner,
	Sentence,
	Selector,
	SentenceWrapper,
	TitleMobile,
	BottomLinks,
	Link,
} from "./episode.styled";
import { useEpisode } from "./use-episode";
import { useApi } from "../../contexts";

const getActive = (startSec: number, lengthSec: number, trackPos: number) =>
	trackPos >= startSec && trackPos < startSec + lengthSec ? "active" : "";

const toMinSec = (s: number) => {
	const minutes = Math.floor(s / 60).toString();
	const seconds = Math.floor(s % 60).toString();
	return `${minutes.length < 2 ? "0" + minutes : minutes}:${
		seconds.length < 2 ? "0" + seconds : seconds
	}`;
};

const getOffsetsById = (id: string) => {
	const el = document.getElementById(id);

	if (!el) return undefined;

	const height = el.offsetHeight;
	const top = el.offsetTop;
	return { height, top };
};

export const EpisodeNextPage = () => {
	const {
		segments,
		trackPos,
		isPlaying,
		lemma,
		links,
		dict,
		title,
		duration,
		play,
		prev,
		next,
		pause,
		seek,
		stop,
		setLemma,
	} = useEpisode();
	const { push } = useHistory();
	const location = useLocation();
	const { episodes, books } = useApi();

	const returnToAnnotation = () => {
		const episodeId = location.pathname.split("/").reverse()[0];
		const episode = episodes.find((episode) => episode.id === Number(episodeId));
		if (!episode) return;
		const { bookId } = episode;
		const book = books.find((book) => book.id === bookId);
		if (!book) return;
		const { authorId } = book;
		push(`/author/${authorId}?book=${bookId}`);
	};

	return (
		<Wrapper>
			<Navigation page="episode" />
			<Reader>
				<Controls>
					<Icons>
						<Icon
							src="/img/step-backward-solid.svg"
							alt="Backward button"
							onClick={prev}
						/>
						{isPlaying ? (
							<Icon
								src="/img/pause-circle-regular.svg"
								alt="Pause button"
								onClick={pause}
							/>
						) : (
							<Icon
								src="/img/play-circle-regular.svg"
								alt="Play button"
								onClick={play}
							/>
						)}
						<Icon
							src="/img/undo-solid.svg"
							alt="Backward button"
							onClick={() => seek(0)}
						/>
						<Icon
							src="/img/step-forward-solid.svg"
							alt="Forward button"
							onClick={next}
						/>
					</Icons>
					<div>{`${toMinSec(trackPos)} / ${toMinSec(duration ?? 0)}`}</div>
					<Title onClick={returnToAnnotation}>{title}</Title>
				</Controls>
				<Row>
					<EpisodeText>
						<TitleMobile onClick={returnToAnnotation}>&nbsp;&nbsp;{title}</TitleMobile>
						{Object.entries(segments).map(([k, v], par) => {
							const startSeg = v[0].startSec;
							const segLength =
								v[v.length - 1].startSec + v[v.length - 1].lengthSec - startSeg;
							return (
								<div
									key={`${k}-${par}`}
									className={`paragraph ${k} ${getActive(
										startSeg,
										segLength,
										trackPos
									)}`}
								>
									{v.map(({ words, startSec, lengthSec }, i) => (
										<SentenceWrapper>
											<Selector
												style={getOffsetsById(`sentence-${k}-${i}`)}
												onClick={() => {
													seek(startSec);
													play();
												}}
											/>
											<Sentence id={`sentence-${k}-${i}`}>
												{words.map(({ text, trail, lead, lemma }, j) => {
													return (
														<span
															className={
																"word " +
																getActive(startSec, lengthSec, trackPos)
															}
															key={`${k}-${i}-${j}`}
															onClick={() => setLemma(lemma)}
														>
															{lead}
															{text}
															{trail}&nbsp;
														</span>
													);
												})}
											</Sentence>
										</SentenceWrapper>
									))}
								</div>
							);
						})}
						<BottomLinks>
							{links.prev && (
								<Link
									onClick={() => {
										stop();
										push(`/episode/${links.prev}`);
									}}
								>
									&lt;&lt; Предыдущий
								</Link>
							)}
							{links.next && (
								<Link
									className="next"
									onClick={() => {
										stop();
										push(`/episode/${links.next}`);
									}}
								>
									Следующий &gt;&gt;
								</Link>
							)}
						</BottomLinks>
					</EpisodeText>
					<Dict className={!!lemma && dict[lemma] ? "opened" : "closed"}>
						{lemma && dict[lemma] ? (
							<DictInner>
								<Lemma>
									{lemma}
									<Close onClick={() => setLemma(undefined)}>
										<img
											src="/img/close-dict-mobile.svg"
											alt="Close dictionary"
										/>
									</Close>
								</Lemma>
								<Senses>
									{dict[lemma].senses.map(({ srcDef }) => {
										return <Sense>{srcDef}</Sense>;
									})}
								</Senses>
							</DictInner>
						) : (
							<DictInner>
								Click on a word in the text to show dictionary entries.
							</DictInner>
						)}
					</Dict>
				</Row>
			</Reader>
		</Wrapper>
	);
};
