import { useLocation } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useDevice } from "../../contexts";
import {
	Authorship,
	Navigation,
	SoundButton,
	BasicCard,
	BasicGrammarCard,
	Tabs,
	Tab,
	Head,
} from "../../components";
import { Letter } from "./letter";
import { Wrapper, Col, Grid, GrammarGrid } from "./basics.styled";
import { LETTERS } from "./letters";
import { BasicsMobilePage } from "./basics-mobile";
import { useBasics } from "./hooks";
import { getVideos } from "../../utils";
import { useVideoModal } from "../../contexts";

const tabs = {
	alphabet: "tab-alphabet",
	grammar: "tab-grammar",
	contact: "tab-contact",
} as const;

const pageHashes = {
	alphabet: "alphabet",
	grammar: "grammar",
	contact: "first-contact",
} as const;

const to = (id: string) => {
	document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
};

export const BasicsPage = () => {
	const {
		basics,
		basicsGrammar,
		basicsContacts,
		currentLetter,
		handleButtonClick,
		handleLetterClick,
		stopPlaying,
		onImageLoad,
		finishedLoading,
	} = useBasics();
	const { isMobile } = useDevice();
	const videos = getVideos(basics);
	const videosGrammar = getVideos(basicsGrammar);
	const videosContacts = getVideos(basicsContacts);
	const { search } = useLocation();
	const { isOpened, showVideo } = useVideoModal();

	const videoId = useMemo(() => {
		return new URLSearchParams(search).get("step");
	}, [search]);

	const grammarVideoId = useMemo(() => {
		return new URLSearchParams(search).get("grammarId");
	}, [search]);

	const contactVideoId = useMemo(() => {
		return new URLSearchParams(search).get("contactId");
	}, [search]);

	useEffect(() => {
		if (!videoId || isOpened || !window.location.search.includes("step"))
			return;
		if (Number.isNaN(+videoId)) return;
		if (~videos.findIndex((el) => el.videoId === videoId)) return;

		setTimeout(() => {
			showVideo(videos, +videoId);
		}, 500);
	}, [showVideo, videoId, videos, isOpened]);

	useEffect(() => {
		if (
			!grammarVideoId ||
			isOpened ||
			!window.location.search.includes("grammarId")
		)
			return;
		if (Number.isNaN(+grammarVideoId)) return;
		if (~videosGrammar.findIndex((el) => el.videoId === grammarVideoId)) return;

		setTimeout(() => {
			showVideo(videosGrammar, +grammarVideoId);
		}, 500);
	}, [showVideo, grammarVideoId, isOpened, videosGrammar]);

	useEffect(() => {
		if (
			!contactVideoId ||
			isOpened ||
			!window.location.search.includes("contactId")
		)
			return;
		if (Number.isNaN(+contactVideoId)) return;
		if (~videosContacts.findIndex((el) => el.videoId === contactVideoId))
			return;

		setTimeout(() => {
			showVideo(videosContacts, +contactVideoId);
		}, 500);
	}, [showVideo, contactVideoId, isOpened, videosContacts]);

	basics.forEach((el) => {
		onImageLoad(el.imgSrc);
	});

	basicsGrammar.forEach((el) => {
		onImageLoad(el.imgSrc);
	});

	basicsContacts.forEach((el) => {
		onImageLoad(el.imgSrc);
	});

	useEffect(() => {
		const navigateToSection = () => {
			if (!finishedLoading) return;
			const hash = window.location.hash;
			if (!hash) return;

			let element = null;
			switch (hash) {
				case `#${pageHashes.alphabet}`:
					element = document.getElementById(tabs.alphabet);
					break;
				case `#${pageHashes.grammar}`:
					element = document.getElementById(tabs.grammar);
					break;
				case `#${pageHashes.contact}`:
					element = document.getElementById(tabs.contact);
					break;
				default:
					return;
			}

			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		};

		navigateToSection();
	}, [finishedLoading]);

	const handleTabClick = (id: string, hash: string) => {
		to(id);
		window.history.pushState(null, "", `#${hash}`);
	};

	if (isMobile) {
		return <BasicsMobilePage />;
	}

	return (
		<Wrapper>
			<Head
				title="Into Russian - First Steps"
				description="An animated alphabet and basic grammar for beginners listen watch read learn Russian"
				canonical="basics"
			/>
			<Navigation page="basics" />
			<Col style={{ marginTop: "3.4vw" }}>
				<div style={{ marginBottom: "1vw", fontFamily: "BitterBold" }}>
					First Steps. For Beginners (A0+)
				</div>
				<div style={{ marginBottom: "4vw" }}>
					An animated alphabet and basic grammar
				</div>
			</Col>

			<Tabs id={tabs.alphabet}>
				<Tab className="selected">Alphabet</Tab>
				<Tab
					onClick={() => {
						handleTabClick(tabs.grammar, pageHashes.grammar);
					}}
				>
					Grammar
				</Tab>
				<Tab
					onClick={() => {
						handleTabClick(tabs.contact, pageHashes.contact);
					}}
				>
					First Contact
				</Tab>
			</Tabs>

			<Col style={{ margin: "3.4vw 0" }}>
				<div>
					{LETTERS.slice(0, 18).map((letter, i) => (
						<Letter
							key={letter}
							letter={letter}
							isPlaying={letter === currentLetter}
							onClick={() => handleLetterClick(letter, i)}
						/>
					))}
				</div>
				<div
					style={{ display: "flex", alignItems: "center", marginTop: "1vw" }}
				>
					{LETTERS.slice(18).map((letter, i) => (
						<Letter
							key={letter}
							letter={letter}
							isPlaying={letter === currentLetter}
							onClick={() => handleLetterClick(letter, i + 18)}
						/>
					))}
					<SoundButton
						isPlaying={!!currentLetter}
						onClick={handleButtonClick}
					/>
				</div>
			</Col>
			
			<Grid>
				{basics.map((basic) => {
					return (
						<BasicCard
							key={basic.id}
							{...basic}
							videos={videos}
							stopAudio={stopPlaying}
						/>
					);
				})}
			</Grid>

			<Tabs id={tabs.grammar}>
				<Tab
					onClick={() => {
						handleTabClick(tabs.alphabet, pageHashes.alphabet);
					}}
				>
					Alphabet
				</Tab>
				<Tab className="selected">Grammar</Tab>
				<Tab
					onClick={() => {
						handleTabClick(tabs.contact, pageHashes.contact);
					}}
				>
					First Contact
				</Tab>
			</Tabs>

			<GrammarGrid>
				{basicsGrammar.map((el) => {
					return (
						<BasicGrammarCard
							key={el.id}
							videos={videosGrammar}
							stopAudio={stopPlaying}
							videoKey="grammarId"
							{...el}
						/>
					);
				})}
			</GrammarGrid>

			<Tabs id={tabs.contact}>
				<Tab
					onClick={() => {
						handleTabClick(tabs.alphabet, pageHashes.alphabet);
					}}
				>
					Alphabet
				</Tab>
				<Tab
					onClick={() => {
						handleTabClick(tabs.grammar, pageHashes.grammar);
					}}
				>
					Grammar
				</Tab>
				<Tab className="selected">First Contact</Tab>
			</Tabs>

			<GrammarGrid>
				{basicsContacts.map((el) => {
					return (
						<BasicGrammarCard
							key={el.id}
							videos={videosContacts}
							stopAudio={stopPlaying}
							videoKey="contactId"
							{...el}
						/>
					);
				})}
			</GrammarGrid>

			<Authorship page="basics" />
			<Navigation page="basics" footer />
		</Wrapper>
	);
};
