import {
	createContext,
	useContext,
	useCallback,
	useState,
	useEffect,
} from "react";
import axios from "axios";

import { toTags, toDate, getBooksWithLength } from "../utils";
import {
	ApiContext,
	Basic,
	BasicGrammar,
	BasicContact,
	Story,
	Person,
	Author,
	Book,
	Episode,
	Creator,
	AboutPageContent,
	AboutPageCard,
	AboutPageCreator,
	TagCategory,
} from "../types";

const API_URL =
	"https://www.intorussian.slavistik.uni-muenchen.de/wp-json/acf/v3/";
const PER_PAGE = 100;

enum Endpoint {
	basics = "basics",
	basicsGrammar = "basics_grammar",
	basicsContacts = "basics_contacts",
	stories = "stories",
	persons = "persons",
	authors = "authors",
	books = "books",
	episodes = "episodes",
	creators = "creators",
	aboutPageContent = "about_page_content",
	aboutPageCards = "about_page_cards",
	aboutPageCreators = "about_page_creators",
	tagCategories = "tag_categories",
	personsTagCategories = "pers_tag_categories",
}

const defaultContext = {
	basics: [],
	basicsGrammar: [],
	basicsContacts: [],
	stories: [],
	persons: [],
	authors: [],
	books: [],
	episodes: [],
	creators: [],
	aboutPageContent: [],
	aboutPageCards: [],
	aboutPageCreators: [],
	tagCategories: [],
	personsTagCategories: [],
};

const Context = createContext<ApiContext>(defaultContext);
Context.displayName = "ApiContext";

const fetch = async (endpoint: Endpoint): Promise<any[]> => {
	let res: any[] = [];
	let page = 1;

	while (true) {
		try {
			const { statusText, data } = await axios.get(
				`${API_URL}${endpoint}/?per_page=${PER_PAGE}&page=${page}`
			);

			if (statusText !== "OK") break;

			if (!Array.isArray(data) || data.length === 0) break;

			res = res.concat(data);

			if (data.length < PER_PAGE) break;

			page = page + 1;
		} catch (e) {
			break;
		}
	}

	return res;
};

export const useApi = () => useContext(Context);

export const ApiProvider: React.FC = (props) => {
	const [basics, setBasics] = useState<Basic[]>([]);
	const [basicsGrammar, setBasicsGrammar] = useState<BasicGrammar[]>([]);
	const [basicsContacts, setBasicsContacts] = useState<BasicContact[]>([]);
	const [stories, setStories] = useState<Story[]>([]);
	const [persons, setPersons] = useState<Person[]>([]);
	const [authors, setAuthors] = useState<Author[]>([]);
	const [books, setBooks] = useState<Book[]>([]);
	const [tagCategories, setTagCategories] = useState<TagCategory[]>([]);
	const [personsTagCategories, setPersonsTagCategories] = useState<TagCategory[]>([]);
	const [episodes, setEpisodes] = useState<Episode[]>([]);
	const [creators, setCreators] = useState<Creator[]>([]);
	const [aboutPageContent, setAboutPageContent] = useState<AboutPageContent[]>(
		[]
	);
	const [aboutPageCards, setAboutPageCards] = useState<AboutPageCard[]>([]);
	const [aboutPageCreators, setAboutPageCreators] = useState<
		AboutPageCreator[]
	>([]);
	const [booksLengthUpdated, setBooksLengthUpdated] = useState(false);

	const fetchBasics = useCallback(async () => {
		try {
			const fetched = await fetch(Endpoint.basics);

			if (fetched.length === 0) return;

			const collator = new Intl.Collator();

			const basicsNext: Basic[] = fetched
				.map((el) => ({
					id: el.id,
					letter: el.acf?.letter,
					word: el.acf?.word,
					example: el.acf?.example,
					imgSrc: el.acf?.image?.url,
					videoSrc: el.acf?.video_id,
					tags: toTags(el.acf?.tags),
					createdAt: toDate(el.acf?.image?.modified),
					transcript: el.acf?.transcript,
				}))
				.sort((a, b) => {
					return collator.compare(a.letter, b.letter);
				});

			setBasics(basicsNext);
		} catch (e) {
			// TODO: add logging context
			console.error(e);
		}
	}, []);

	const fetchBasicsGrammar = useCallback(async () => {
		try {
			const fetched = await fetch(Endpoint.basicsGrammar);

			if (fetched.length === 0) return;

			const grammarsNext: BasicGrammar[] = fetched
				.map((el) => ({
					id: el.id,
					titleRu: el.acf?.title_ru,
					titleEn: el.acf?.title_en,
					descriptionEn: el.acf?.description_en,
					descriptionRu: el.acf?.description_ru,
					imgSrc: el.acf?.image?.url,
					videoSrc: el.acf?.video_id,
					createdAt: toDate(el.acf?.image?.modified),
					transcript: el.acf?.transcript,
					order: Number(el.acf?.order ?? 9999),
				}))
				.sort((a, b) => a.order - b.order);

			setBasicsGrammar(grammarsNext);
		} catch (e) {
			// TODO: add logging context???
			console.error(e);
		}
	}, []);

	const fetchBasicsContacts = useCallback(async () => {
		try {
			const fetched = await fetch(Endpoint.basicsContacts);

			if (fetched.length === 0) return;

			const contactsNext: BasicGrammar[] = fetched
				.map((el) => ({
					id: el.id,
					titleRu: el.acf?.title_ru,
					titleEn: el.acf?.title_en,
					descriptionEn: el.acf?.description_en,
					descriptionRu: el.acf?.description_ru,
					imgSrc: el.acf?.image?.url,
					videoSrc: el.acf?.video_id,
					createdAt: toDate(el.acf?.image?.modified),
					transcript: el.acf?.transcript,
					order: Number(el.acf?.order ?? 9999),
				}))
				.sort((a, b) => a.order - b.order);

			setBasicsContacts(contactsNext);
		} catch (e) {
			// TODO: add logging context???
			console.error(e);
		}
	}, []);

	const fetchStories = useCallback(async () => {
		try {
			const fetched = await fetch(Endpoint.stories);

			if (fetched.length === 0) return;

			const storiesNext: Story[] = fetched
				.map((el) => ({
					id: el.id,
					titleRu: el.acf?.title_ru,
					titleEn: el.acf?.title_en,
					description: el.acf?.description_ru,
					imgSrc: el.acf?.image?.url,
					videoSrc: el.acf?.video_id,
					tags: toTags(el.acf?.tags),
					createdAt: toDate(el.acf?.image?.modified),
					transcript: el.acf?.transcript,
					order: Number(el.acf?.order ?? 9999),
				}))
				.sort((a, b) => a.order - b.order);

			setStories(storiesNext);
		} catch (e) {
			// TODO: add logging context
			console.error(e);
		}
	}, []);

	const fetchPersons = useCallback(async () => {
		try {
			const fetched = await fetch(Endpoint.persons);

			if (fetched.length === 0) return;

			const personsNext: Person[] = fetched
				.map((el) => ({
					id: el.id,
					titleRu: el.acf?.title_ru,
					titleEn: el.acf?.title_en,
					descriptionEn: el.acf?.description_en,
					descriptionRu: el.acf?.description_ru,
					imgSrc: el.acf?.image?.url,
					videoSrc: el.acf?.video_id,
					tags: toTags(el.acf?.tags),
					createdAt: toDate(el.acf?.image?.modified),
					transcript: el.acf?.transcript,
					order: Number(el.acf?.order ?? 9999),
				}))
				.sort((a, b) => a.order - b.order);

			setPersons(personsNext);
		} catch (e) {
			// TODO: add logging context???
			console.error(e);
		}
	}, []);

	const fetchAuthors = useCallback(async () => {
		try {
			const fetched = await fetch(Endpoint.authors);

			if (fetched.length === 0) return;

			const authorsNext: Author[] = fetched.map((el) => ({
				id: el.id,
				nameRu: el.acf?.author_name_ru,
				shortNameRu: el.acf?.author_short_name_ru,
				nameEn: el.acf?.author_name_en,
				years: el.acf?.years_of_life,
				descriptionEn: el.acf?.description_en,
				descriptionRu: el.acf?.description_ru,
				imgSrc: el.acf?.image?.url,
				order: Number(el.acf?.order ?? 9999),
			}));

			setAuthors(authorsNext.sort((a, b) => a.order - b.order));
		} catch (e) {
			// TODO: add logging context???
			console.error(e);
		}
	}, []);

	const fetchBooks = useCallback(async () => {
		try {
			const fetched = await fetch(Endpoint.books);

			if (fetched.length === 0) return;

			const booksNext: Book[] = fetched.map((el) => ({
				id: el.id,
				title: el.acf?.title,
				subtitle: el.acf?.subtitle,
				order: Number(el.acf?.order ?? 9999),
				tags: toTags(el.acf?.tags),
				authorId: el.acf?.book_author?.[0]?.ID,
				length: 0,
				imgSrc: el.acf?.image,
				imgDesc: el.acf?.image_description,
				visible: el.acf?.visible,
			}));

			setBooks(booksNext.sort((a, b) => a.order - b.order));
		} catch (e) {
			// TODO: add logging context???
			console.error(e);
		}
	}, []);

	const fetchTagCategories = useCallback(async () => {
		try {
			const fetched = await fetch(Endpoint.tagCategories);

			if (fetched.length === 0) return;

			const tagCategoriesNext: TagCategory[] = fetched.map((el) => ({
				title: el.acf?.title,
				selected: el.acf?.selected ?? false,
				order: Number(el.acf?.order ?? 9999),
				tags: toTags(el.acf?.tags),
			}));

			setTagCategories(tagCategoriesNext.sort((a, b) => a.order - b.order));
		} catch (e) {
			// TODO: add logging context???
			console.error(e);
		}
	}, []);

	const fetchPersonsTagCategories = useCallback(async () => {
		try {
			const fetched = await fetch(Endpoint.personsTagCategories);

			if (fetched.length === 0) return;

			const personsTagCategoriesNext: TagCategory[] = fetched.map((el) => ({
				title: el.acf?.title,
				selected: el.acf?.selected ?? false,
				order: Number(el.acf?.order ?? 9999),
				tags: toTags(el.acf?.tags),
			}));

			setPersonsTagCategories(
				personsTagCategoriesNext.sort((a, b) => a.order - b.order)
			);
		} catch (e) {
			// TODO: add logging context???
			console.error(e);
		}
	}, []);



	const fetchEpisodes = useCallback(async () => {
		try {
			const fetched = await fetch(Endpoint.episodes);

			if (fetched.length === 0) return;

			const episodesNext: Episode[] = fetched.map((el) => ({
				id: el.id,
				content: el.acf?.content,
				contentFormatted: el.acf?.content_formatted,
				order: Number(el.acf?.episode_number ?? 9999),
				bookId: el.acf?.book?.[0]?.ID,
				jsonSrc: el.acf?.json_data,
				m4aSrc: el.acf?.audio_m4a,
				webmSrc: el.acf?.audio_webm,
				title: el.acf?.title,
			}));

			setEpisodes(episodesNext);
		} catch (e) {
			// TODO: add logging context???
			console.error(e);
		}
	}, []);

	const fetchCreators = useCallback(async () => {
		try {
			const fetched = await fetch(Endpoint.creators);

			if (fetched.length === 0) return;

			const creatorsNext: Creator[] = fetched.map((el) => ({
				id: el.id,
				title: el.acf?.title,
				titleEn: el.acf?.title_en,
				description: el.acf?.description,
				descriptionEn: el.acf?.description_en,
				isOnTop: Boolean(el.acf?.isontop),
				imgSrc: el.acf?.image?.url,
				order: Number(el.acf?.order ?? 9999),
			}));

			setCreators(creatorsNext);
		} catch (e) {
			// TODO: add logging context???
			console.error(e);
		}
	}, []);

	const fetchAboutPageContent = useCallback(async () => {
		try {
			const fetched = await fetch(Endpoint.aboutPageContent);

			if (fetched.length === 0) return;

			const aboutPageContentNext: AboutPageContent[] = fetched.map((el) => ({
				id: el.id,
				titleRu: el.acf?.title_ru,
				titleEn: el.acf?.title_en,
				subtitleRu: el.acf?.subtitle_ru,
				subtitleEn: el.acf?.subtitle_en,
				teamTitleRu: el.acf?.team_title_ru,
				teamTitleEn: el.acf?.team_title_en,
				teamSubtitleRu: el.acf?.team_subtitle_ru,
				teamSubtitleEn: el.acf?.team_subtitle_en,
				thanksTitleRu: el.acf?.thanks_title_ru,
				thanksTitleEn: el.acf?.thanks_title_en,
				thanksSubtitleRu: el.acf?.thanks_subtitle_ru,
				thanksSubtitleEn: el.acf?.thanks_subtitle_en,
			}));

			setAboutPageContent(aboutPageContentNext);
		} catch (e) {
			// TODO: add logging context???
			console.error(e);
		}
	}, []);

	const fetchAboutPageCards = useCallback(async () => {
		try {
			const fetched = await fetch(Endpoint.aboutPageCards);

			if (fetched.length === 0) return;

			const aboutPageCardsNext: AboutPageCard[] = fetched.map((el) => ({
				id: el.id,
				titleRu: el.acf?.title_ru,
				titleEn: el.acf?.title_en,
				descriptionRu: el.acf?.description_ru,
				descriptionEn: el.acf?.description_en,
				href: el.acf?.href,
				imgSrc: el.acf?.image,
				order: Number(el.acf?.order ?? 9999),
			}));

			setAboutPageCards(aboutPageCardsNext.sort((a, b) => a.order - b.order));
		} catch (e) {
			// TODO: add logging context???
			console.error(e);
		}
	}, []);

	const fetchAboutPageCreators = useCallback(async () => {
		try {
			const fetched = await fetch(Endpoint.aboutPageCreators);

			if (fetched.length === 0) return;

			const aboutPageCreatorsNext: AboutPageCreator[] = fetched.map((el) => ({
				id: el.id,
				nameRu: el.acf?.name_ru,
				nameEn: el.acf?.name_en,
				roleRu: el.acf?.role_ru,
				roleEn: el.acf?.role_en,
				imgSrc: el.acf?.image,
				isStudent: el.acf?.is_student,
				order: Number(el.acf?.order ?? 9999),
			}));

			setAboutPageCreators(
				aboutPageCreatorsNext.sort((a, b) => a.order - b.order)
			);
		} catch (e) {
			// TODO: add logging context???
			console.error(e);
		}
	}, []);

	useEffect(() => {
		if (books.length > 0 && episodes.length > 0 && !booksLengthUpdated) {
			const booksNext = getBooksWithLength(episodes, books);
			setBooksLengthUpdated(true);
			setBooks(booksNext);
		}
	}, [books, episodes, booksLengthUpdated]);

	useEffect(() => {
		Promise.all([
			fetchBasics(),
			fetchBasicsGrammar(),
			fetchBasicsContacts(),
			fetchStories(),
			fetchPersons(),
			fetchAuthors(),
			fetchBooks(),
			fetchEpisodes(),
			fetchCreators(),
			fetchAboutPageContent(),
			fetchAboutPageCards(),
			fetchAboutPageCreators(),
			fetchTagCategories(),
			fetchPersonsTagCategories(),
		]);
	}, []); /* eslint-disable-line react-hooks/exhaustive-deps */

	return (
		<Context.Provider
			value={{
				basics,
				basicsGrammar,
				basicsContacts,
				stories,
				persons,
				authors,
				books,
				episodes,
				creators,
				aboutPageContent,
				aboutPageCards,
				aboutPageCreators,
				tagCategories,
				personsTagCategories,
			}}
		>
			{props.children}
		</Context.Provider>
	);
};
