import { useApi, useDevice } from "../../contexts";
import { Navigation, AuthorCard, Bookshelf, Head } from "../../components";
import { Wrapper, Col, Grid, Row, SearchInput, SearchResults } from "./literature.styled";
import { LiteratureMobilePage } from "./literature-mobile";
import { isAuthorResult, isBookResult, useLiteratureSearch } from "./hooks";
import { SearchAuthorCard, SearchBookCard, SearchNotFountCard } from "../../components/search-card/search-card";
import { useEffect, useMemo, useRef, useState } from "react";
import SearchDropdown from "../../components/search-dropdown/search-dropdown";

export const LiteraturePage = () => {
	const { authors } = useApi();
	const { isMobile } = useDevice();
	const { search, setSearch, searchResults, searchResultsVisible, setSearchResultsVisible, lastSearch, onSearch } = useLiteratureSearch();
	const [scrollTarget, setScrollTarget] = useState<HTMLElement | null>(null);
	const searchInputRef = useRef<HTMLDivElement>(null);
	const [showSearchDropdown, setShowSearchDropdown] = useState(false);
	const term = useMemo(() => search.toLocaleLowerCase().trim(), [search]);

	useEffect(() => {
		if (scrollTarget && !searchResultsVisible) {
			scrollTarget.scrollIntoView({ behavior: "smooth", block: "center" });
		}
		if (scrollTarget && searchResultsVisible) {
			setScrollTarget(null);
		}
		if (!searchResultsVisible) {
			setSearch("");
		}
	}, [searchResultsVisible, scrollTarget, setSearch]);

	useEffect(() => {
		if (searchResultsVisible) return;
		if (searchResults && searchResults.length > 0) {
			setShowSearchDropdown(true);
		}
	}, [searchResults, searchResultsVisible]);

	if (isMobile) {
		return <LiteratureMobilePage />;
	}

	return (
		<>
		<Wrapper>
			<Head
				title="Into Russian - Literature"
				description="Russian literature to listen and read with vocabulary. Thematic guide of Russian literature: listen, watch, read"
				canonical="literature"
			/>
			<Navigation page="literature" />
			<Col style={{ marginTop: "3.4vw", marginBottom: "3.4vw" }}>
				<div style={{ marginBottom: "1vw", fontFamily: "BitterBold" }}>Literature. For All Levels</div>
				<div>
					A listening and reading library
				</div>
			</Col>

			<Row style={{
				marginBottom: "3.4vw",
			}}>
				<Col>
					<div
						style={{
							fontFamily: "Bold",
							cursor: "pointer",
							display: "flex",
							alignItems: "center",
							textDecoration: "underline",
							fontSize: "1.6vw",
						}}
						onClick={() => {
							setSearchResultsVisible(false);
							setScrollTarget(document.getElementById("book-cards"));
							if (scrollTarget && !searchResultsVisible) {
								scrollTarget.scrollIntoView({ behavior: "smooth", block: "center" });
							}
						}}
					>
						<img
							src="/img/ico_slider_right.png"
							alt=""
							style={{ marginRight: "12px" }}
						/>
						See the thematic guide{" "}
					</div>
				</Col>
				<SearchInput ref={searchInputRef}>
						<input
							type="text" 
							placeholder="Search by author or title"
							maxLength={100}
							value={search || ""}
							onChange={(e) => setSearch(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === "Enter") {
									onSearch();
								}
							}}
						/>
						<img
							src="/img/search-icon.svg"
							alt=""
							onClick={onSearch}
						/>
				</SearchInput>
			</Row>
			{!searchResultsVisible && 
			<Grid>
				{authors.map((author) => {
					return <AuthorCard key={author.id} {...author} />;
				})}
			</Grid>
			}

			{searchResultsVisible && searchResults &&
				<SearchResults>
					<h2>Search Results: {lastSearch}</h2>
					{searchResults.map((item) => {
						let card;
						if (isAuthorResult(item)) {
							card = <SearchAuthorCard key={item.data.id} 
												{...item.data}
										 />;
						}
						if (isBookResult(item)) {
							card = <SearchBookCard key={item.data.id} {...item.data} />;
						}
						return card || null;
					})}
					{searchResults.length === 0 &&
						<SearchNotFountCard />
					}
				</SearchResults>
			}

			
			<div id="book-cards" />
			{!searchResultsVisible &&
				<Bookshelf />
			}

			<Navigation page="literature" footer />
		</Wrapper>

		{showSearchDropdown && (
				<SearchDropdown
					searchResults={searchResults.sort((a, b) => {
						const termA = isAuthorResult(a) ? a.data.nameRu.toLocaleLowerCase() : isBookResult(a) ? a.data.title.toLocaleLowerCase() : "";
						const termB = isAuthorResult(b) ? b.data.nameRu.toLocaleLowerCase() : isBookResult(b) ? b.data.title.toLocaleLowerCase() : "";
						// first return if starts with term
						if (termA.startsWith(term) && !termB.startsWith(term)) return -1;
						if (!termA.startsWith(term) && termB.startsWith(term)) return 1;
						// then return in alphabetical order
						return termA.localeCompare(termB);
					})}
					searchInputRef={searchInputRef}
					setShowSearchDropdown={setShowSearchDropdown}
					search={search}
					setSearch={setSearch}
					onSearch={onSearch}
				/>
			)}
		</>
	);
};
