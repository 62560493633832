import styled from "styled-components";

export const Overlay = styled.div<{ isOpened: boolean }>(
	({ isOpened }) => `
  ${isOpened ? "" : "max-height: 0; max-width: 0;"}
	z-index: 100;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
`
);

export const Wrapper = styled.div<{ isOpened: boolean }>(
	({ isOpened }) => `
	position: fixed;
	top: 20px;
	right: ${isOpened ? "-5vw" : "7.5vw"};
	width: ${isOpened ? "70vw" : "12.5vw"};
	height: ${isOpened ? "100vh" : "12.5vw"};
	border-radius: ${isOpened ? "4.5vw" : "50%"};
	background: ${
		isOpened
			? "linear-gradient(180deg, #B2C3BB 0%, rgba(178, 195, 187, 0) 31.35%), #DBE1D9"
			: "white"
	};
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: space-between;
  z-index: 1000;
  transition: all ${isOpened ? "0.6s" : "0.2s"} ease;
  @media (orientation: landscape) {
	  top: 6.8vh;
	  width: ${isOpened ? "70vw" : "12.5vh"};
	  height: ${isOpened ? "100vh" : "12.5vh"};
  }
`
);

export const Bars = styled.div<{ isOpened: boolean }>(
	({ isOpened }) => `
	display: flex;
  width: 100%;
  height: 100%;
  ${isOpened ? "max-width: 0px; max-height: 0px;" : ""}
	flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${isOpened ? "0" : "1"};
  transition-delay: 0.3s;
  transition-property: opacity;
  transition-duration: 1s;
  ${isOpened ? "transition: none;" : ""}

	.bar1,
	.bar2,
	.bar3 {
		width: 6.4vw;
		height: 0.8vw;
		background-color: #82937d;
		margin: 0.5vw 0;
		transition: 0.4s;
	}

  @media (orientation: landscape) {
    .bar1,
    .bar2,
    .bar3 {
      width: 6.4vh;
      height: 0.8vh;
		  margin: 0.5vh 0;
    }
  }
`
);

export const Content = styled.div<{ isOpened: boolean }>(
	({ isOpened }) => `
  max-height: ${isOpened ? "unset" : "0"};
  opacity: ${isOpened ? "1" : "0"};
  padding-left: 10.5vw;
  padding-right: 10vw;
  padding-top: ${isOpened ? "10.5vw" : "0"};
  transition-delay: 0.3s;
  transition-property: opacity, height;
  transition-duration: 1s;
  ${isOpened ? "" : "transition: none;"} 

  ${isOpened ? "" : "div { display: none; }"} 
  `
);

export const Cross = styled.div`
	content: "";
	height: 5vw;
	width: 5vw;
	position: relative;

	:before,
	:after {
		content: "";
		position: absolute;
		width: 5vw;
		height: 1vw;
		background-color: white;
		border-radius: 2px;
		right: 1vw;
	}
	:before {
		transform: rotate(45deg);
	}
	:after {
		transform: rotate(-45deg);
	}
`;

export const CloseVideo = styled.div`
	content: "";
	height: 100%;
	width: 100%;
	position: relative;

	:before,
	:after {
		content: "";
		position: absolute;
		width: 6.5vw;
		height: 1vw;
		background-color: #82937d;
		border-radius: 2px;
		top: 48%;
		left: 25%;
		transform: translate(-50%, -50%);
	}
	:before {
		transform: rotate(45deg);
	}
	:after {
		transform: rotate(-45deg);
	}
`;

export const Row = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
	font-family: Bitter;
	font-size: 4.5vw;

	a {
		margin-top: 5.4vw;
		text-decoration: none;
		color: black;
		font-family: BitterLight;
	}

	b {
		font-family: BitterBold;
	}
`;

export const List = styled.ul`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	list-style: none;

	a {
		margin-top: 5vw;
		margin-left: 4.2vw;
		font-family: Bitter;
	}
`;
