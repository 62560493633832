import styled from "styled-components";

const Wrapper = styled.div`
	display: flex;
	margin-top: 2.6vw;

	@media (max-width: 1023px) {
		min-height: 10vw;
	}
`;

const Media = styled.img`
	height: 2.6vw;
	width: 2.6vw;
	margin-right: 2.7vw;
	@media (max-width: 1023px) {
		height: 8.8vw;
		width: 8.8vw;
		margin-right: 5.8vw;
	}
`;

export const SocialMedia = () => {
	return (
		<Wrapper>
			<a
				href="https://www.youtube.com/channel/UCQDtdxOgsIYeFzVUHo8vm7w"
				target="_blank"
				rel="noreferrer noopener"
			>
				<Media alt="Youtube channel Into Russian" src="/img/youtube.svg" />
			</a>

			<a
				href="https://www.facebook.com/Into-Russian-106549325091368"
				target="_blank"
				rel="noreferrer noopener"
			>
				<Media src="/img/facebook.svg" />
			</a>
			<a
				href="mailto:intorussian.info@gmail.com"
				target="_blank"
				rel="noreferrer noopener"
			>
				<Media src="/img/mail.svg" />
			</a>
		</Wrapper>
	);
};
