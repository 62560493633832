import { useApi } from "../../contexts";
import {
	BasicCard,
	StoryCard,
	PersonCard,
	AuthorCard,
	Slider,
	Navigation,
} from "../../components";

import FancyNav from "./components/fancy-nav";

import { Wrapper } from "./main.styled";
import { getVideos } from "../../utils";

export const MainPage = () => {
	const { basics, stories, persons, authors } = useApi();
	const basicsVideos = getVideos(basics);
	const storiesVideos = getVideos(stories);
	const personsVideos = getVideos(persons);

	return (
		<Wrapper>
			<Navigation page="main" />
			<FancyNav />
			<Slider
				title="First Steps"
				subtitle="An animated alphabet and basic grammar"
				level="For Beginners (A0+)."
				to="/basics"
			>
				{basics.map((basic) => (
					<BasicCard key={basic.id} videos={basicsVideos} {...basic} />
				))}
			</Slider>

			<Slider
				title="Stories"
				subtitle="Animated fables, tales and short texts"
				level="For Novice and Intermediate (A1/A2+)."
				to="/stories"
			>
				{stories.map((story) => (
					<StoryCard key={story.id} videos={storiesVideos} {...story} />
				))}
			</Slider>

			<Slider
				title="People"
				subtitle="Videos and podcasts about people and their lives"
				level="From Novice (A1+) to Advanced (C)."
				to="/people"
				slidesToShow={3}
			>
				{persons.map((person) => (
					<PersonCard key={person.id} videos={personsVideos} {...person} />
				))}
			</Slider>

			<Slider
				title="Literature"
				subtitle="Who’s afraid of Dostoevsky? A listening and reading library"
				level="For All Levels."
				to="/literature"
				slidesToShow={2}
			>
				{authors.map((author) => (
					<AuthorCard key={author.id} {...author} />
				))}
			</Slider>
			<Navigation page="main" footer />
		</Wrapper>
	);
};
