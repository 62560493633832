import styled from "styled-components";

export const Overlay = styled.div`
	z-index: 100;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
`;

export const Wrapper = styled.div`
	z-index: 101;
	width: 94vw;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: relative;
	background: transparent;

	@media (max-width: 1023px) {
		width: 100vw;
		top: 0;
		left: 0;
		right: 0;
		transform: unset;
	}
`;

export const Footer = styled.div`
	display: flex;
	justify-items: space-between;
	color: white;
	text-decoration: underline;

	@media (max-width: 1023px) {
		display: none;
	}
`;

export const Title = styled.div`
	font-family: RobotoCondensed;
	font-size: 1.45vw;
	cursor: pointer;
	user-select: none;
`;

export const WatchAll = styled.div`
	display: flex;
	align-items: center;
	font-family: RobotoCondensedLight;
	font-size: 1.45vw;
	line-height: 150%;
	font-weight: 300;
	margin-left: auto;
	cursor: pointer;
	user-select: none;
`;

export const SquaresImg = styled.div`
	background-image: url(img/squares-white.svg);
	width: 1.45vw;
	height: 1.45vw;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	margin-left: 0.5vw;
`;

export const TranscriptImg = styled.div`
	background-image: url(img/transcript.svg);
	width: 1.45vw;
	height: 1.45vw;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	margin-left: 0.5vw;
`;

export const Transcript = styled.div`
	width: 84vw;
	height: 66vh;
	z-index: 1000;

	background: white;
	position: fixed;
	bottom: 0;
	left: 8vw;
	border-top-right-radius: 1.6vw;
	border-top-left-radius: 1.6vw;

	padding: 6vw 4vw 1vw 4vw;

	& > div {
		padding: 0 1vw 1vw 1vw;
		overflow-y: auto;
		max-height: 100%;
	}
`;
