import { BrowserRouter, Switch, Route } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import { ApiProvider, VideoModalProvider, DeviceProvider } from "./contexts";

import {
	AboutPage,
	MainPage,
	BasicsPage,
	StoriesPage,
	PeoplePage,
	LiteraturePage,
	AuthorPage,
	EpisodeNextPage,
} from "./pages";
import { VideoModal, ScrollToTop } from "./components";

smoothscroll.polyfill();

function App() {
	return (
		<ApiProvider>
			<DeviceProvider>
				<BrowserRouter>
					<VideoModalProvider>
						<ScrollToTop />
						<Switch>
							<Route path="/about">
								<AboutPage />
							</Route>
							<Route path="/basics">
								<BasicsPage />
							</Route>
							<Route path="/stories">
								<StoriesPage />
							</Route>
							<Route path="/people">
								<PeoplePage />
							</Route>
							<Route path="/literature">
								<LiteraturePage />
							</Route>
							<Route path="/author/:id">
								<AuthorPage />
							</Route>
							<Route path="/episode/:id">
								<EpisodeNextPage />
							</Route>
							<Route path="/">
								<MainPage />
							</Route>
						</Switch>
						<VideoModal />
					</VideoModalProvider>
				</BrowserRouter>
			</DeviceProvider>
		</ApiProvider>
	);
}

export default App;
