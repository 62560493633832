import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.8vw;

  border-radius: 1vw;
  padding: 1.8vw;
  background: #ffffff;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1023px) {
    gap: 2.9vw;

    border-radius: 4.4vw;
    width: 70vw;
    padding: 5.3vw;
    padding-bottom: 7.3vw;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1.8vw;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
    
    & > div {
      margin-bottom: 0;
    }
  }
`;

export const BookImg = styled.div<{ src: string }>(
  ({ src }) => `
  width: 12.4vw;
  height: 12.4vw;
  background-image: url(${src});
  background-size: cover;
  background-position: center;
  border-radius: 0.5vw;

  @media (max-width: 1023px) {
    width: 25vw;
    height: 25vw;
  }
`
);

export const Headers = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.7vw;

  & h2 {
    font-size: 1.8vw;
    color: #242424;
    font-family: BitterBold;
  }

  & h3 {
    font-size: 1.5vw;
    color: #242424;
    font-family: BitterBold;
    text-decoration: underline;
  }

  & h4 {
    font-size: 1.1vw;
    color: #242424;
    font-family: BitterBold;
  }

  @media (max-width: 1023px) {
    gap: 1.5vw;

    & h2 {
      font-size: 5.5vw;
    }

    & h3 {
      font-size: 4.5vw;
      text-decoration: none;
    }

    & h4 {
      font-size: 3.3vw;
    }
  }
`;

export const Shield = styled.p`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-bottom: auto;
  height: 2.8vw;
  border-radius: 2vw;
  padding: 0.6vw 1.7vw;
  
  background: #58A8B4;
  color: #ffffff;
  font-size: 1.06vw;
  font-family: BitterBold;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const Description = styled.p`
  font-size: 1.4vw;
  color: #242424;
  font-family: Regular;

  @media (max-width: 1023px) {
    font-size: 3.4vw;
  }
`;