import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useApi, useDevice } from "../../contexts";
import { Book as BookType } from "../../types";
import { isEpisodeClickable } from "../../utils";
import { BookOpenedMobile } from "./book-opened-mobile";
import { appear } from "../../constants";

const Container = styled.div`
	min-height: 48vw;
	margin-bottom: 12px;
	border-radius: 12px;
	font-size: 1.25vw;
	display: flex;
	background: linear-gradient(
			270deg,
			rgba(255, 255, 255, 0.2) 39.06%,
			rgba(148, 149, 149, 0.2) 50%,
			rgba(255, 255, 255, 0.2) 50.01%
		),
		#ffffff;

	${appear}
`;

const Episodes = styled.div`
	flex-grow: 1;
	width: 50%;
	padding: 40px;
	display: flex;
	flex-direction: column;
`;
const Desc = styled.div`
	flex-grow: 1;
	width: 50%;
	padding: 40px;
	padding-top: 76px;

	display: flex;
	flex-direction: column;
	align-items: center;

	img {
		width: 100%;
		border-radius: 12px;
		margin-bottom: 12px;
	}
`;

const ImgText = styled.div`
	font-size: 1vw;
	width: 100%;
`;

export const Episode = styled.div`
	margin-top: 1vw;
`;

const Author = styled.div`
	font-family: Bitter;
	font-size: 1vw;
	color: #000;
	margin-top: auto;
	margin-left: auto;

	b {
		font-family: BitterBold;
	}
`;

export const Number = styled.div`
	font-family: Bold;
	text-decoration: underline;
`;

export const Content = styled.div``;

export const Read = styled.div`
	color: #50a41d;
	text-decoration: underline;
`;

export const BookOpened = (props: BookType) => {
	const { id, title, authorId, imgSrc, imgDesc } = props;
	const { episodes, authors } = useApi();
	const history = useHistory();
	const { isMobile } = useDevice();

	const episodesByBook = episodes
		.filter(({ bookId }) => bookId === id)
		.sort((a, b) => a.order - b.order);
	const isEpisodeNumberVisible = episodesByBook.length > 1;

	const author = authors.find((el) => el.id === authorId);

	if (isMobile) return <BookOpenedMobile {...props} />;

	return (
		<Container id={`opened-book-${id}`}>
			<Episodes>
				{episodesByBook.map((episode) => {
					return (
						<Episode
							key={episode.id}
							style={{
								cursor: isEpisodeClickable(episode) ? "pointer" : "default",
							}}
							onClick={() =>
								isEpisodeClickable(episode) &&
								history.push(`/episode/${episode.id}`)
							}
						>
							{isEpisodeNumberVisible &&
								(episode.title ? (
									<Number>{episode.title}</Number>
								) : (
									<Number>Эпизод {episode.order}</Number>
								))}
							{episode.contentFormatted &&
							episode.contentFormatted.length > 0 ? (
								<Content
									dangerouslySetInnerHTML={{ __html: episode.contentFormatted }}
								/>
							) : (
								<Content>{episode.content}</Content>
							)}
							{isEpisodeClickable(episode) && <Read>Читать &gt;&gt;</Read>}
						</Episode>
					);
				})}
			</Episodes>
			<Desc>
				{imgSrc && <img src={imgSrc} alt="" />}
				{imgDesc && <ImgText dangerouslySetInnerHTML={{ __html: imgDesc }} />}
				<Author>
					{author?.nameRu ?? ""}&nbsp;
					<b>{title}</b>
				</Author>
			</Desc>
		</Container>
	);
};
