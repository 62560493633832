import { useVideoModal } from "../../contexts";
import { Basic, VideoItem } from "../../types";

import {
	Wrapper,
	Letter,
	TitleContainer,
	Word,
	Example,
	PlayImg,
} from "./basic-card.styled";

export const BasicCard = ({
	letter,
	word,
	example,
	imgSrc,
	videoSrc,
	videos,
	stopAudio,
}: Basic & { videos: VideoItem[]; stopAudio?: () => void }) => {
	const { showVideo } = useVideoModal();
	const videoId = videos.findIndex((el) => el.videoId === videoSrc);
	return (
		<Wrapper
			imgSrc={imgSrc}
			className={`basic-card-wrapper letter-${letter} ${
				!!videoSrc && " has-video"
			}`}
			onClick={() => {
				if (!videoSrc || typeof videoId !== "number") return;
				stopAudio && stopAudio();

				showVideo(videos, videoId);
				const url = new URL(window.location.toString());
				url.searchParams.set("step", videoId.toString());
				window.history.pushState(null, "", url.toString());
			}}
		>
			<TitleContainer>
				<Letter>{letter}</Letter>
				<Word>{word}</Word>
			</TitleContainer>
			<Example dangerouslySetInnerHTML={{ __html: example }} />
			{videoSrc && <PlayImg />}
		</Wrapper>
	);
};
