import { useState } from "react";
import { BasicGrammar, VideoItem } from "../../types";
import { useVideoModal } from "../../contexts";

import {
	Wrapper,
	ImgContainer,
	Title,
	Description,
	Lang,
} from "./basic-grammar-card.styled";

export const BasicGrammarCard = ({
	titleRu,
	titleEn,
	descriptionRu,
	descriptionEn,
	imgSrc,
	videoSrc,
	videos,
	stopAudio,
	videoKey,
}: BasicGrammar & {
	videos: VideoItem[];
	videoKey: string;
	stopAudio?: () => void;
}) => {
	const { showVideo } = useVideoModal();
	const [isEnShown, setIsEnShown] = useState(false);
	const videoId = videos.findIndex((el) => el.videoId === videoSrc);

	return (
		<Wrapper
			className="person-card-wrapper"
			onClick={() => {
				if (!videoSrc || typeof videoId !== "number") return;
				stopAudio && stopAudio();

				showVideo(videos, videoId);
				const url = new URL(window.location.toString());
				url.searchParams.set(videoKey, videoId.toString());
				window.history.pushState(null, "", url.toString());
			}}
		>
			<ImgContainer src={imgSrc} alt={titleEn} />
			{isEnShown && <Title>{titleEn}</Title>}
			{!isEnShown && <Title>{titleRu}</Title>}
			{isEnShown && <Description>{descriptionEn}</Description>}
			{!isEnShown && <Description>{descriptionRu}</Description>}
			<Lang
				onClick={(e) => {
					e.stopPropagation();
					setIsEnShown(!isEnShown);
				}}
			>
				<div>{isEnShown ? "RUS" : "EN"}</div>
			</Lang>
		</Wrapper>
	);
};
