import { Link } from "react-router-dom";
import { useDevice } from "../../contexts";
import { Rights, SocialMedia } from "../../components";
import { Wrapper, Logo, Right } from "./navigation.styled";

import { NavigationMobile } from "./navigation-mobile";

export const Navigation = ({
	page,
	footer,
	className,
}: {
	page: string;
	footer?: boolean;
	className?: string;
}) => {
	const { isMobile } = useDevice();

	if (isMobile) {
		return (
			<NavigationMobile page={page} footer={footer} className={className} />
		);
	}

	return (
		<>
			<Wrapper
				className={`navigation ${className ?? ""}`}
				style={{
					marginTop: footer ? "6.8vw" : "0",
					padding: page === "episode" ? "0.5vw 16vw" : "1.7vw 0",
				}}
			>
				<Logo
					className="logo"
					style={{ fontSize: page === "main" ? "2.9vw" : "2.4vw" }}
				>
					<Link to="/">
						into
						{page === "episode" ? <>&nbsp;</> : <br />}
						russian
					</Link>
				</Logo>
				{page === "main" && !footer && (
					<Right
						style={{
							fontFamily: "RobotoCondensed",
							textAlign: "right",
							lineHeight: "150%",
							fontSize: "1.6vw",
						}}
					>
						Discover the Real Russian Language <br />
						Listen Watch Read Learn: From the Basics to Tolstoy
					</Right>
				)}
				{(footer || page !== "main") && (
					<Right>
						<Link className={page === "basics" ? "active" : ""} to="/basics">
							First Steps
						</Link>
						<Link className={page === "stories" ? "active" : ""} to="/stories">
							Stories
						</Link>
						<Link className={page === "people" ? "active" : ""} to="/people">
							People
						</Link>
						<Link
							className={page === "literature" ? "active" : ""}
							to="/literature"
						>
							Literature
						</Link>
						<Link className={page === "about" ? "active" : ""} to="/about">
							About
						</Link>
					</Right>
				)}
			</Wrapper>
			{footer && <SocialMedia />}
			{footer && <Rights />}
		</>
	);
};
