import { Wrapper } from "./youtube-video.styled";

export const YoutubeVideo = ({ videoId }: { videoId: string }) => (
	<Wrapper>
		<iframe
			width="853"
			height="480"
			src={`https://www.youtube.com/embed/${videoId}`}
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen
			title="Embedded youtube"
		/>
	</Wrapper>
);
