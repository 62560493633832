import styled from "styled-components";

const Wrapper = styled.span<{ isPlaying: boolean }>(
	({ isPlaying }) => `
	margin-right: 0.5vw;
  cursor: pointer;
  line-height: 1.2;
  white-space: nowrap;

  @media(max-width: 1023px) {
    margin-left: 1vw;
  }

  ${isPlaying && `color: #4F8B3B; font-family: Bold;`}


`
);

export const Letter = ({
	letter,
	isPlaying,
	onClick,
}: {
	letter: string;
	isPlaying: boolean;
	onClick?: () => void;
}) => {
	return (
		<Wrapper isPlaying={isPlaying} onClick={onClick}>
			{letter}
		</Wrapper>
	);
};
