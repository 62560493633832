import React from 'react'
import { BookExtended } from '../../pages/literature/hooks'
import { BookImg, Description, Headers, Shield, Top, Wrapper } from './search-card.styled'
import { Author } from '../../types'
import { Img, ImgContainer } from '../author-card/author-card.styled'
import { useHistory } from 'react-router-dom'
import { useApi } from '../../contexts'
import { getFirstEpisode, isEpisodeClickable } from '../../utils'

interface Props {
  title: string,
  imgSrc: string,
  author?: string,
  authorId?: string | number,
  subtitle: string,
  description?: string,
  shieldText: string,
  onClickCard?: () => void,
  onClickAuthor?: () => void,
};

export const SearchCard = (item: Props) => {
  const { title, imgSrc, author, subtitle, description, shieldText, onClickCard, onClickAuthor } = item;
  return (
    <Wrapper onClick={onClickCard}>
      <Top>
        {shieldText === 'ТЕКСТ' &&
          <BookImg src={imgSrc} />
        }
        {shieldText === 'АВТОР' &&
          <ImgContainer>
            <Img src={imgSrc} />
          </ImgContainer>
        }
        <Headers>
          <h2>{title}</h2>
          {shieldText === 'ТЕКСТ' && 
            <h3
              onClick={(e) => {
                e.stopPropagation();
                onClickAuthor && onClickAuthor();
              }}
            >{author}</h3>
          }
          <h4>{subtitle}</h4>
        </Headers>
        <Shield>
          {shieldText}
        </Shield>
      </Top>
      {description &&
        <Description>
          {description}
        </Description>
      }
    </Wrapper>
  )
};

export const SearchAuthorCard = (
  {
    id,
    nameRu,
    years,
    imgSrc,
    descriptionEn,
  }: Author
) => {
  const history = useHistory();
  return (
    <SearchCard
      title={nameRu}
      subtitle={years}
      imgSrc={imgSrc}
      author={nameRu}
      authorId={id}
      description={descriptionEn}
      shieldText={'АВТОР'}
      onClickCard={() => {
        history.push(`/author/${id}`);
      }}
    />
  )
};

export const SearchBookCard = (
  {
    id,
    title,
    subtitle,
    authorId,
    authorName,
    annotation,
  }: BookExtended
) => {
  const { episodes } = useApi();
  const history = useHistory();
  return (
    <SearchCard 
      title={title}
      subtitle={subtitle}
      author={authorName}
      authorId={authorId}
      imgSrc='/img/book.svg'
      description={annotation}
      shieldText='ТЕКСТ'
      onClickCard={() => {
        const firstEpisode = getFirstEpisode(episodes, id);
        isEpisodeClickable(firstEpisode) && history.push(`/episode/${firstEpisode.id}`);
      }}
      onClickAuthor={() => {
        history.push(`/author/${authorId}`);
      }}
    />
  )
};

export const SearchNotFountCard = () => {
  return (
    <Wrapper>
      <Top>
        <BookImg src={'/img/book.svg'} />
        <Headers>
          <h2>{`К сожалению, ничего не найдено :(`}</h2>
          <h2>{`Sorry, we didn’t found this book or author :(`}</h2>
        </Headers>
      </Top>
    </Wrapper>
  )
}