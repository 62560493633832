import styled from "styled-components";
import { appear } from "../../constants";

export const Wrapper = styled.div`
	min-height: 100vh;
	min-width: 100vw;
	padding: 6.8vw;
	background: #bec8cb;
	${appear}

	.author-card-wrapper {
		margin-bottom: 20px;
		margin-left: auto;
		margin-right: auto;
	}
`;

export const Col = styled.div`
	display: flex;
	flex-direction: column;
	font-family: Bitter;
	font-size: 4.5vw;
`;

export const SearchInput = styled.div`
	align-self: center;
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-bottom: 8.2vw;
	width: 82.4vw;
	height: 7.4vw;

	border-radius: 3.6vw;
	border: 2px solid #ffffff;
	padding-left: 8vw;
	padding-right: 7.8vw;

	& > input {
		width: 85%;
		height: 100%;
		border: none;
		outline: none;
		background: transparent;
		font-size: 3.4vw;
		font-family: Bitter;
	}

	& > input::placeholder {
		color: #575757;
	}

	& > img {
		height: 4.26vw;
	}

	& > img:hover {
		cursor: pointer;
	}
`;

export const SearchModal = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #bec8cb;
	z-index: 100;

	display: flex;
	flex-direction: column;
	align-items: center;
	
	& .modal-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0.7vw;

		height: 19.4vw;
		padding-left: 6.1vw;
		padding-right: 8.2vw;

		background: #fff;
	}

	& .modal-top img {
		height: 6.1vw;
	}

	& .modal-top input {
		margin-left: 4.2vw;
		margin-right: 3.2vw;
		width: 100%;
		
		border: none;
		outline: none;
		font-size: 6.4vw;
		font-family: BitterBold;
	}

	& .modal-top input::placeholder {
		color: #575757;
	}
`;

export const SearchResults = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2vw;

	& > h2 {
		align-self: flex-start;
		
		font-size: 2.2vw;
		font-family: BitterBold;
	}

	@media (max-width: 1023px) {
		gap: 4vw;

		& > h2 {
			font-size: 4.5vw;
		}
	}
`;