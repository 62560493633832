import styled from "styled-components";

export const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: white;
	width: 100%;
`;

export const Logo = styled.div`
	font-family: Sirotica;
	font-size: 2.4vw;
	line-height: 0.9;

	a {
		text-decoration: none;
		color: white;
	}
`;

export const Right = styled.div`
	font-family: Regular;
	font-size: 2vw;

	a {
		color: white;
		text-decoration: none;
		margin-left: 1.5vw;
	}

	a.active {
		font-family: Bold;
	}
`;
