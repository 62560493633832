import styled from "styled-components";

export const Wrapper = styled.div`
	overflow: hidden;
	position: relative;
	height: 90vh;
	width: 100%;

	iframe {
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		position: absolute;
	}

	@media (max-width: 1023px) {
		height: 100vh;
	}
	@media (max-width: 550px) {
		height: 50vh;
		min-height: 50vh;
		width: 100vw;
		margin-top: 9vw;
	}
`;
