import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

const Scroll = ({ history }: RouteComponentProps) => {
	useEffect(() => {
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0);
		});

		return () => {
			unlisten();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};

export const ScrollToTop = withRouter(Scroll);
