import { useApi, useDevice } from "../../contexts";
import { useEffect, useMemo } from "react";
import {
	Authorship,
	Navigation,
	StoryCard,
	Tags,
	Head,
} from "../../components";
import { StoriesMobilePage } from "./stories-mobile";
import { useLocation } from "react-router-dom";
import { useTags } from "../../hooks";
import { Wrapper, Col, Grid } from "./stories.styled";
import { useVideoModal } from "../../contexts";
import { getVideos } from "../../utils";

export const StoriesPage = () => {
	const { stories } = useApi();
	const { isMobile } = useDevice();
	const { tags, selectedTags, itemsVisible, handleTagClick } = useTags(
		stories,
		true
	);
	const videos = getVideos(stories);
	const { search } = useLocation();
	const { isOpened, showVideo } = useVideoModal();

	const videoId = useMemo(() => {
		return new URLSearchParams(search).get("story");
	}, [search]);

	useEffect(() => {
		// if (!videoId && isOpened) {
		// 	hideVideo();
		// }
		if (!videoId || isOpened || !window.location.search.includes("story"))
			return;
		if (Number.isNaN(+videoId)) return;
		if (~videos.findIndex((el) => el.videoId === videoId)) return;

		setTimeout(() => {
			showVideo(videos, +videoId);
		}, 500);
	}, [showVideo, videoId, videos, isOpened]);

	if (isMobile) {
		return <StoriesMobilePage />;
	}

	return (
		<Wrapper>
			<Head
				title="Into Russian - Stories"
				description="Animated fables, tales and stories for intermediate learners listen watch read Russian"
				canonical="stories"
			/>
			<Navigation page="stories" />
			<Col style={{ marginTop: "3.4vw", marginBottom: "3.4vw" }}>
				<div style={{ marginBottom: "1vw", fontFamily: "BitterBold" }}>
					Stories. For Novice and Intermediate (A1/A2+)
				</div>
				<div>
					Animated fables, tales and short texts for intermediate learners
				</div>
			</Col>
			<Tags
				tags={[...tags].sort((a, b) => a.localeCompare(b))}
				page="stories"
				selected={selectedTags}
				handleTagClick={handleTagClick}
			/>
			<Grid>
				{itemsVisible.map((story) => {
					return <StoryCard key={story.id} videos={videos} {...story} />;
				})}
			</Grid>
			<Authorship page="stories" />
			<Navigation page="stories" footer />
		</Wrapper>
	);
};
