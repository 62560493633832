import { useCallback, useEffect, useMemo, useState } from "react";
import { Author, Book, Episode } from "../../types";
import { useApi } from "../../contexts";
import { fuzzyDistance } from "../../utils";

export interface BookExtended extends Book {
  annotation?: string;
  authorName?: string;
}

export interface SearchResult {
  type: string;
  data: Author | BookExtended;
}

export const isAuthorResult = (searchResult: SearchResult): searchResult is {type: 'author', data: Author} => {
  return searchResult.type === 'author';
}

export const isBookResult = (searchResult: SearchResult): searchResult is {type: 'book', data: BookExtended} => {
  return searchResult.type === 'book';
}

const MAX_STR_DISTANCE = 3;

export const useLiteratureSearch = () => {
	const [search, setSearch] = useState<string>("");
  const [lastSearch, setLastSearch] = useState<string>("");
	const [term, setTerm] = useState<string>("");
	const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
	const [searchResultsVisible, setSearchResultsVisible] = useState<boolean>(
		false
	);	

	const {books, episodes, authors} = useApi();

	const booksWithAnnotations = useMemo(() => {
		const booksCopy: BookExtended[] = books.map(book => ({...book}));
		const episodesMap = new Map<number, Episode[]>();
		for (const episode of episodes) {
			const bookEpisodes = episodesMap.get(episode.bookId) ?? [];
			if (episode.content) bookEpisodes.push(episode);
			episodesMap.set(episode.bookId, bookEpisodes);
		};
    const authorsMap = new Map<number, Author>();
    for (const author of authors) {
      authorsMap.set(author.id, author);
    }
		// choose first episode's content as annotation
		for (const book of booksCopy) {
			const episodes = episodesMap.get(book.id);
      const author = authorsMap.get(book.authorId);
			if (episodes && episodes.length > 0) {
				episodes.sort((a, b) => a.order - b.order);
				book.annotation = episodes[0].content;
			}
      book.authorName = author?.nameRu ?? '';
		}
		return booksCopy;
	}, [books, episodes, authors]);

	const performSearch = useMemo(() => {
		const results: SearchResult[] = [];
		const foundAuthors = authors
			.filter((author) =>
				author.nameEn.toLowerCase().includes(term)
				|| author.nameRu.toLowerCase().includes(term)
				|| fuzzyDistance(term, author.nameRu)[0] <= MAX_STR_DISTANCE
				|| fuzzyDistance(term, author.nameEn)[0] <= MAX_STR_DISTANCE)
      .sort((a, b) => {
				const aSurname = a.nameRu?.split(' ').reverse()[0];
				const bSurname = b.nameRu?.split(' ').reverse()[0];
				if (!aSurname || !bSurname) return 0;
				return aSurname.localeCompare(bSurname);
			});
		const foundBooks: BookExtended[] = [];
		for (const book of booksWithAnnotations) {
			let found = false;
			if (
				book.title.toLowerCase().includes(term)
				|| fuzzyDistance(term, book.title)[0] <= MAX_STR_DISTANCE
			) {
				found = true;
			}
			if (found) {
				foundBooks.push(book);
			}
		}
		
		// group found books by author
		const booksByAuthor = new Map<number, BookExtended[]>();
		for (const book of foundBooks) {
			const books = booksByAuthor.get(book.authorId) ?? [];
			books.push(book);
			booksByAuthor.set(book.authorId, books);
		}
		// sort books by order inside groups
		for (const books of Array.from(booksByAuthor.values())) {
			books.sort((a, b) => a.order - b.order);
		}
		// add found authors and their found books
		for (const author of foundAuthors) {
			results.push({type: 'author', data: author});
			if (booksByAuthor.has(author.id)) {
        const books = (booksByAuthor.get(author.id) ?? []).map(book => ({type: 'book', data: {...book}}));
				results.push(...books);
			}
			booksByAuthor.delete(author.id);
		}
		// add rest of the books
		for (const books of Array.from(booksByAuthor.values())) {
			results.push(...books.map(book => ({type: 'book', data: book})));
		}
		return results;
	}, [
		booksWithAnnotations,
		authors,
		term,
	]);

	const handleSearch = useCallback(() => {
    if (search === lastSearch) return;
    setLastSearch(search);
		setSearchResults(performSearch);
	}, [
		performSearch, search, lastSearch, setLastSearch
	]);

  const onSearch = useCallback(() => {
    if (!term || term.length === 0) {
			setSearchResultsVisible(false);
			return
		};
		handleSearch();
		setSearchResultsVisible(true);
  }, [term, handleSearch]);

	useEffect(() => {
		const term = search.toLocaleLowerCase();
		setTerm(term);
		const timeout = setTimeout(() => {
			if (term.trim()) handleSearch();
		}, 500);
		return () => clearTimeout(timeout);
	}, [search, handleSearch]);

	return {
		search,
		setSearch,
		handleSearch,
		searchResults,
		searchResultsVisible,
		setSearchResultsVisible,
    lastSearch,
    setLastSearch,
    onSearch
	};
};