import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
	Wrapper,
	Basics,
	Stories,
	People,
	Literature,
	Row,
	Col,
} from "./fancy-nav-mobile.styled";

export const FancyNavMobile = () => {
	const history = useHistory();
	const go = useCallback(
		(url) => {
			setTimeout(() => {
				history.push(url);
			}, 200);
		},
		[history]
	);

	return (
		<Wrapper>
			<Row>
				<Col style={{ marginRight: "1.7vw" }}>
					<Basics aria-haspopup="true" onClick={() => go("/basics")}>
						<div className="title">First Steps</div>
					</Basics>
					<Literature onClick={() => go("/literature")}>
						<div className="title">Literature</div>
					</Literature>
				</Col>
				<Col>
					<Stories onClick={() => go("/stories")}>
						<div className="title">Stories</div>
					</Stories>
					<People onClick={() => go("/people")}>
						<div className="title">People</div>
					</People>
				</Col>
			</Row>
		</Wrapper>
	);
};
